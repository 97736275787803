import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { map, catchError } from 'rxjs/operators';
@Component({
  selector: 'app-index-it-solution-two',
  templateUrl: './index-it-solution-two.component.html',
  styleUrls: ['./index-it-solution-two.component.css'],
  encapsulation: ViewEncapsulation.None
})

/***
 * It-Solution-Two Component
 */
export class IndexItSolutionTwoComponent implements OnInit {

  /***
   * nav bg class class set
   */
  navClass = 'nav-light';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  @ViewChild('newsletter', { static: true }) newsletter: TemplateRef<any>;

  /**
  * Member Data
  */
  memberData = [
    {
      profile: "assets/images/client/01.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Ronny Jofra",
      designation: "C.E.O"
    },
    {
      profile: "assets/images/client/04.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Micheal Carlo",
      designation: "Director"
    },
    {
      profile: "assets/images/client/02.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Aliana Rosy",
      designation: "Manager"
    },
    {
      profile: "assets/images/client/03.jpg",
      list: ["facebook", "instagram", "twitter", "linkedin"],
      name: "Sofia Razaq",
      designation: "Developer"
    }
  ];

  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Thomas Israel",
      designation: "C.E.O",
      message: `" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "`
    },
    {
      profile: "assets/images/client/02.jpg",
      name: "Barbara McIntosh",
      designation: "M.D",
      message: `" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`
    },
    {
      profile: "assets/images/client/03.jpg",
      name: "Carl Oliver",
      designation: "P.A",
      message: `" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`
    },
    {
      profile: "assets/images/client/04.jpg",
      name: "Christa Smith",
      designation: "Manager",
      message: `" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "Dean Tolle",
      designation: "Developer",
      message: `" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`
    },
    {
      profile: "assets/images/client/06.jpg",
      name: "Jill Webb",
      designation: "Designer",
      message: `" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "`
    }
  ];

  /**
   * Blog Data
   */

  /**
  * Services Data
  */
  pricingData = [
    {
      title: "1 & 2 Layers",
      price: 2,
      list: ["Full Access", "Source Files",],
      btn: "Get in Touch"
    },
    {
      // warning: "Best",
      title: "4&6 Layers",
      price: 39,
      list: ["Full Access", "Source Files"],
      btn: "Get in Touch"
    },
    {
      title: "PCB Assembly",
      price: 59,
      list: ["Full Access", "Source Files"],
      btn: "Get in Touch"
    },
    {
      title: "SMT Stencil",
      price: 79,
      list: ["Full Access", "Source Files"],
      btn: "Get in Touch"
    }
  ];
  servicesData = [
    {
      icon: "uil uil-edit-alt h1 text-primary",
      title: "Excellent Quality PCBs",
      description: "We have our own factories with fully automated production lines to provide high quality and consistency. Certified to ISO 9001:2015, ISO 14001:2015, and IPC-6012E."
    },
    {
      icon: "uil uil-vector-square h1 text-primary",
      title: "Fast & Stable Delivery",
      description: "Get boards produced 24 hours and delivered in 2-4 days. Over 98% of Orders were shipped on time. To iterate more freely as sharacircuits offers low-cost and fast-turnaround services."
    },
    {
      icon: "uil uil-file-search-alt h1 text-primary",
      title: "24 Hour Support",
      description: "Our friendly support team is available via email(2-hour average response time on office hours), Live Chat, and phone. The real person to help any time of day."
    },

  ];
  blogData = [
    {
      image: "assets/images/blog/01.jpg",
      title: "Design your apps in your own way",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    },
    {
      image: "assets/images/blog/02.jpg",
      title: "How apps is changing the IT world",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    },
    {
      image: "assets/images/blog/03.jpg",
      title: "Smartest Applications for Business",
      like: "33",
      message: "08",
      name: "Calvin Carlo",
      date: "13th August, 2019"
    }
  ];

  constructor(private modalService: NgbModal) {
    // this._doGetdevicelist();
    // this._doGet30daysgraph();
 
  }

  ngOnInit(): void {
    //this.modalService.open(this.newsletter);
  }

  /**
  * Open modal for show the video
  */
  // openWindowCustomClass(content) {
  //   this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  // }

  isVisible: boolean = true;

  hideOfferBox() {
    this.isVisible = false;
  }

}








