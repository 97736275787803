import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  public response: any;
  private url: string = ' https://h717c9ua5d.execute-api.ap-south-1.amazonaws.com/prod/';
  // private url: string = 'https://cz3r0w8bf3.execute-api.ap-south-1.amazonaws.com/prod/';
  public headerstr = new HttpHeaders({'Content-Type': 'application/json'  }) //"Authorization": sessionStorage.getItem("key")
  constructor(
    private http: HttpClient,
 
  ) { 
   console.log("hi")
  }

  login(url, data): Promise<string> {
    return this.http
      .post(this.url + url, data)
      .toPromise()
      .then();
  }

  postService(url: string, data: any): Observable<any> {
    return this.http.post(this.url + url, data).pipe(
      map(resp => this.response = resp)
    )
  }
  
  putservice(url, data): Promise<string> {
    return this.http
      .put( url, data)
      .toPromise()
      .then();
  }
  postmethod(url: string, data: any): Observable<any> {
    // if (null === localStorage.getItem("key")) {
    //     this.headerstr = new HttpHeaders({ "Authorization": localStorage.getItem("token") })
    //    }
    return this.http.post(this.url + url,data).pipe(
      map(resp => this.response = resp)
    )
  }
 
  putmethod(url: string, data: any): Observable<any> {
    // if (null === localStorage.getItem("key")) {
    //     this.headerstr = new HttpHeaders({ "Authorization": localStorage.getItem("token") })
    //    }
    return this.http.put(url,data, { observe: 'response' }).pipe(
      map(resp => this.response = resp)
    )
  }
}
