import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { IndexItSolutionTwoComponent } from './core/components/index-it-solution-two/index-it-solution-two.component';
import { IndexOnepageComponent } from './core/components/index-onepage/index-onepage.component';
import { PageAboutusTwoComponent } from './core/components/page-aboutus-two/page-aboutus-two.component';
import { PageAboutusComponent } from './core/components/page-aboutus/page-aboutus.component';
import { PageMaintenanceComponent } from './core/components/page-maintenance/page-maintenance.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageTeamComponent } from './core/components/page-team/page-team.component';
import { PageTermsComponent } from './core/components/page-terms/page-terms.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { PageFooterComponent } from './core/components/page-footer/page-footer.component';
import { Product1Component } from './core/components/product1/product1.component';
import { Product2Component } from './core/components/product2/product2.component';
import { Product3Component } from './core/components/product3/product3.component';
import { Product4Component } from './core/components/product4/product4.component';
import { Product5Component } from './core/components/product5/product5.component';
import { Product6Component } from './core/components/product6/product6.component';
import { Product7Component } from './core/components/product7/product7.component';
import { Product8Component } from './core/components/product8/product8.component';
import { Product9Component } from './core/components/product9/product9.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { PageContactTwoComponent } from './core/components/page-contact-two/page-contact-two.component';
import { EventsComponent } from './core/components/events/events.component';
import { RequestquoteComponent } from './core/components/requestquote/requestquote.component';
import { TrackOrderComponent } from './core/components/track-order/track-order.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      {
        path: '',
        component: IndexItSolutionTwoComponent,
        data: { title: 'Home - Shara Circuits', description: 'Welcome to Shara Circuits, your one-stop solution for PCB fabrication, assembly, and design services.' }
      },
      {
        path: 'page-aboutus',
        component: PageAboutusComponent,
        data: { title: 'About Us - Shara Circuits', description: 'Learn more about Shara Circuits, our mission, and our team.' }
      },
      {
        path: 'page-aboutus-two',
        component: PageAboutusTwoComponent,
        data: { title: 'About Us (Extended) - Shara Circuits', description: 'More detailed information about Shara Circuits and our services.' }
      },
      {
        path: 'page-contact-us',
        component: PageContactTwoComponent,
        data: { title: 'Contact Us - Shara Circuits', description: 'Get in touch with Shara Circuits for any queries or support.' }
      },
      {
        path: 'page-privacy',
        component: PagePrivacyComponent,
        data: { title: 'Privacy Policy - Shara Circuits', description: 'Read our privacy policy to understand how we handle your data.' }
      },
      {
        path: 'page-team',
        component: PageTeamComponent,
        data: { title: 'Our Team - Shara Circuits', description: 'Meet the dedicated team behind Shara Circuits.' }
      },
      {
        path: 'page-terms',
        component: PageTermsComponent,
        data: { title: 'Terms & Conditions - Shara Circuits', description: 'Our terms and conditions for using Shara Circuits services.' }
      },
      { path: 'switcher', component: SwitcherComponent },
      { path: 'page-footer', component: PageFooterComponent },
      {
        path: 'pcb-fabrication',
        component: Product1Component,
        data: { title: 'PCB Fabrication - Shara Circuits', description: 'Top-quality PCB fabrication services by Shara Circuits.' }
      },
      { path: 'pcb-assembly', component: Product2Component },
      { path: 'pcb-design', component: Product3Component },
      { path: 'pcb-testing-services', component: Product4Component },
      { path: 'pcb-prototype', component: Product5Component },
      { path: 'metal-core-pcb', component: Product6Component },
      { path: 'pcb-component-sourcing', component: Product7Component },
      { path: 'product-development', component: Product8Component },
      { path: 'product9', component: Product9Component },
      { path: 'Events', component: EventsComponent },
      { path: 'Requestquote', component: RequestquoteComponent },
      { path: 'track-order', component: TrackOrderComponent },
    ]
  },
  {
    path: 'index-onepage',
    component: IndexOnepageComponent,
    data: { title: 'Onepage - Shara Circuits', description: 'Explore our onepage solution at Shara Circuits.' }
  },
  {
    path: 'page-maintenance',
    component: PageMaintenanceComponent,
    data: { title: 'Maintenance - Shara Circuits', description: 'Our site is currently under maintenance.' }
  },
];

@NgModule({
  providers: [],
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: "top",
    scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
