import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CountToModule } from 'angular-count-to';
import { NgxMasonryModule } from 'ngx-masonry';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SharedModule } from "./shared/shared.module";
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { IndexOnepageComponent } from './core/components/index-onepage/index-onepage.component';
import { PageAboutusComponent } from './core/components/page-aboutus/page-aboutus.component';
import { PageAboutusTwoComponent } from './core/components/page-aboutus-two/page-aboutus-two.component';
import { PageContactTwoComponent } from './core/components/page-contact-two/page-contact-two.component';
import { PageMaintenanceComponent } from './core/components/page-maintenance/page-maintenance.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageTeamComponent } from './core/components/page-team/page-team.component';
import { PageTermsComponent } from './core/components/page-terms/page-terms.component';
import { FeatherModule } from 'angular-feather';
// Apex chart
import { NgApexchartsModule } from 'ng-apexcharts';
import { allIcons } from 'angular-feather/icons';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IndexItSolutionTwoComponent } from './core/components/index-it-solution-two/index-it-solution-two.component';
import { PageFooterComponent } from './core/components/page-footer/page-footer.component';
import { Product1Component } from './core/components/product1/product1.component';
import { Product2Component } from './core/components/product2/product2.component';
import { Product3Component } from './core/components/product3/product3.component';
import { Product4Component } from './core/components/product4/product4.component';
import { Product5Component } from './core/components/product5/product5.component';
import { Product6Component } from './core/components/product6/product6.component';
import { Product7Component } from './core/components/product7/product7.component';
import { Product8Component } from './core/components/product8/product8.component';
import { Product9Component } from './core/components/product9/product9.component';
import { HttpClientModule } from '@angular/common/http';
import { DataService } from './services/data.service'
import { ToastrModule } from 'ngx-toastr';
import { EventsComponent } from './core/components/events/events.component';
import { RequestquoteComponent } from './core/components/requestquote/requestquote.component';
import { TrackOrderComponent } from './core/components/track-order/track-order.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { NewsletterComponent } from './shared/newsletter/newsletter.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MasterPageComponent,
    IndexOnepageComponent,
    PageAboutusComponent,
    PageAboutusTwoComponent,
    PageContactTwoComponent,
    PageMaintenanceComponent,
    PagePrivacyComponent,
    PageTeamComponent,
    PageTermsComponent,
    SwitcherComponent,
    IndexItSolutionTwoComponent,
    PageFooterComponent,
    Product1Component,
    Product2Component,
    Product3Component,
    Product4Component,
    Product5Component,
    Product6Component,
    Product7Component,
    Product8Component,
    Product9Component,
    EventsComponent,
    RequestquoteComponent,
    TrackOrderComponent,
    NewsletterComponent,
    SwitcherComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    CarouselModule,
    NgbCarouselModule,
    FeatherModule.pick(allIcons),
    ScrollToModule.forRoot(),
    RouterModule.forRoot([], {}),
    NgxYoutubePlayerModule,
    NgbDropdownModule,
    CKEditorModule,
    NgbModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUsefulSwiperModule,
    NgApexchartsModule,
    NgxTypedJsModule,
    FlatpickrModule.forRoot(),
    CountToModule,
    NgxMasonryModule,
    LightboxModule,
    SharedModule,
    SimplebarAngularModule,
    HttpClientModule,
    ToastrModule.forRoot()

  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
