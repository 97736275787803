import { Component } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent {



      // Set Topbar Option
      Menuoption = 'center';
      Settingicon = true
      /**
       * Nav Light Class Add
       */
      navClass = 'nav-light';
    
      constructor() { }
    
      ngOnInit(): void {
      }
    
    
    }
    