<div class="container">
    <div class="row">
        <div class="card">
            <div class="card-header header-txt d-flex">
                <h5>Order Details</h5>
                <form class="form-inline d-flx" name="myForm" #myForm="ngForm" (ngSubmit)="getorderstatus()">
                    <input class="form-control mr-sm-2" [(ngModel)]="orderid" name="orderid" type="search"
                        placeholder="Enter Order Tracking Number" aria-label="Search">
                    <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                </form>
            </div>

            <div class="card-header  d-flex" *ngIf="orderdetails">
                <p style="color: green; font-weight: 900;">Order Date : <span
                        style="color: #000;">{{orderdetails.body.order_timestamp | date: 'dd/MM/yyyy'}}</span> </p>
                <p style="color: green; font-weight: 900;">Order Delivery: <span style="color: #000;">{{orderdetails.body.order_delivery}}</span></p>
                <!-- <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#LoginForm" class="link">Images</a> -->
            </div>

            <div class="row">

            </div>


            <div class="card-body" *ngIf="!orderdetails">
                <div class="track-card">
                    <div>
                        <span *ngFor="let item of counts; let i = index">
                            <span class="circle-desc"
                                 [class.circle-desc-active]="orderStatus == item && counts.length - 1">{{ item }}</span>
                            <span class="circles" [class.active]="orderStatus == item"></span>
                            <span class="bar" *ngIf="i != counts.length - 1"></span>
                        </span>
                    </div>

                </div>
                <div class="card-header">
                    <ul class="nav nav-tabs card-header-tabs">
                        <li class="nav-item">
                            <a class="nav-link active">Order Details</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="row">
                        <h5>Please search for order details</h5>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="orderdetails">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-md-12 col-lg-12">
                                <div id="tracking">
                                    <div class="tracking-list">
                                        <div *ngFor="let item of orderdetails.body.order_status" class="{{item.ItemStatus}}">
                                            <div class="tracking-icon {{item.StatusType}}">
                                                <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true"
                                                    data-prefix="fas" data-icon="circle" role="img"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                    data-fa-i2svg="">
                                                    <path fill="currentColor"
                                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z">
                                                    </path>
                                                </svg>
                                            </div>
                                            <div class="tracking-content">{{item.StatusTitle}}
                                                <!-- <span>
                                                    09 Aug 2021, 11:00am
                                                </span> -->
                                            </div>
                                        </div>
                                        <!-- <div class="tracking-item">
                                    <div class="tracking-icon status-intransit">
                                      <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                      </svg>
                                    </div>
                                    <div class="tracking-content">Order Confirmed<span>09 Aug 2021, 10:30am</span></div>
                                  </div>
                                  <div class="tracking-item">
                                    <div class="tracking-icon status-intransit">
                                      <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                      </svg>
                                    </div>
                                    <div class="tracking-content">Packed the product<span>09 Aug 2021, 12:00pm</span></div>
                                  </div>
                                  <div class="tracking-item">
                                    <div class="tracking-icon status-intransit">
                                      <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                      </svg>
                                    </div>
                                    <div class="tracking-content">Arrived in the warehouse<span>10 Aug 2021, 02:00pm</span></div>
                                  </div>
                                  <div class="tracking-item">
                                    <div class="tracking-icon status-current blinker">
                                      <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                      </svg>
                                    </div>
                                    <div class="tracking-content">Near by Courier facility<span>10 Aug 2021, 03:00pm</span></div>
                                  </div>
                        
                                  <div class="tracking-item-pending">
                                    <div class="tracking-icon status-intransit">
                                      <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                      </svg>
                                    </div>
                                    <div class="tracking-content">Out for Delivery<span>12 Aug 2021, 05:00pm</span></div>
                                  </div>
                                  <div class="tracking-item-pending">
                                    <div class="tracking-icon status-intransit">
                                      <svg class="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                      </svg>
                                    </div>
                                    <div class="tracking-content">Delivered<span>12 Aug 2021, 09:00pm</span></div>
                                  </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="card-header">
                            <ul class="nav nav-tabs card-header-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active">Order Details</a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div class="card_tbl header">
                                        <p class="card-text text-right">Order Id </p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card_tbl">
                                        <p class="card-text">{{orderid}}</p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card_tbl header">
                                        <p class="card-text text-right">Estimated Date of Delivery </p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card_tbl">
                                        <p class="card-text">{{orderdetails.body.estimated_delivery_date}}</p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card_tbl header">
                                        <p class="card-text text-right">Status</p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card_tbl">
                                        <p class="card-text">{{orderdetails.body.current_order_status}}</p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card_tbl header">
                                        <p class="card-text text-right">Recipient</p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card_tbl">
                                        <p class="card-text">{{orderdetails.body.name}}</p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card_tbl header">
                                        <p class="card-text text-right">Images</p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card_tbl">
                                        <!-- <p class="card-text">John Smith</p> -->
                                        <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#LoginForm"
                                            class="link">Images</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <p class="card-text"></p>
                                <p class="card-text">Upload Payment Reciepts/Screenshots Below</p>
                                <input type="file" [(ngModel)]="PCB_file" (change)="onFileChange($event)"
                                    class="form-control" />
                                <button class="btn btn-primary mr-1" (click)="uploadbillfiles()">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<!-- Login Form Start -->
<div class="modal fade" id="LoginForm" tabindex="-1" aria-labelledby="LoginForm-title" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content rounded shadow border-0">
            <div class="modal-header border-bottom">
                <h5 class="modal-title" id="LoginForm-title">Images</h5>
                <button type="button" class="btn btn-icon btn-close" data-bs-dismiss="modal" id="close-modal"><i
                        class="uil uil-times fs-4 text-dark"></i></button>
            </div>
            <div class="modal-body p-0">
                <ngb-carousel>
                    <ng-template ngbSlide *ngFor="let imgs of orderdetails.body.encodedURLs">
                        <div class="picsum-img-wrapper">
                            <img src={{imgs}} alt="Random first slide" />
                        </div>
                    </ng-template>
                    <!-- <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                            <img src="../../../../assets/images/course/prod1.png" alt="Random second slide" />
                        </div>
                    </ng-template> -->
                    <!-- <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                            <img src="../../../../assets/images/course/prod3.png" alt="Random third slide" />
                        </div>
                    </ng-template> -->
                </ngb-carousel>
            </div>
        </div>
    </div>
</div>
<!-- Login Form End -->