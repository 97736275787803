<section class="section" style="padding-bottom:10px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/products/Clipboard6.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h4 class="title mb-4">Mastering the Art of PCB Component Sourcing: Precision, Punctuality, and
                        Performance</h4>
                    <p class="text-muted para-desc mx-auto">
                        Welcome to your one stop solution for PCB component sourcing. At Shara Circuits Pvt Ltd, we
                        understand the global network and we keep
                        a keen eye for quality. We source a wide spectrum of electronic components from resistors,
                        capacitors and diodes to microcontrollers, ICs and more
                    </p>
                    <!-- <p class="text-muted para-desc mx-auto">
                        In the intricate world of PCB assembly, the right components make all the difference. At the
                        heart of a successful PCB project lies meticulous component sourcing, and that's where our
                        expertise comes into play. Delve into our world-class PCB Component Sourcing services and
                        experience the pinnacle of precision and efficiency.
                    </p> -->
                    <div class="mt-4" [ngxScrollTo]="'#getInquiry'">
                        <a href="javascript:void(0)" class="btn btn-primary">Inquiry</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <!--END container-->
</section>

<!-- Start Terms & Conditions -->
<section class="section" style="padding:10px">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Why Entrust Us with Your PCB Component Sourcing Needs?</h5>
                        <p class="text-muted">
                            <b>Vast Network of Suppliers: </b><br>Our expansive network of trusted suppliers ensures you
                            have access to both common and hard-to-find components, fulfilling diverse project
                            requirements.
                        </p>
                        <p class="text-muted">
                            <b>Quality Assured: </b><br>Every component we source undergoes rigorous testing and quality
                            checks. We prioritize authenticity and reliability, ensuring your PCBs function flawlessly.
                        </p>
                        <p class="text-muted">
                            <b>Time-Efficient: </b><br>In the fast-paced electronics world, time is of the essence. Our
                            streamlined sourcing processes ensure components are procured swiftly, keeping your projects
                            on schedule.
                        </p>
                        <p class="text-muted">
                            <b>Cost-Effective Solutions: </b><br>We understand the balance between quality and cost. Our
                            strategic alliances and bulk buying capabilities enable us to source components at
                            competitive prices, ensuring you receive top-tier quality without the hefty price tag.
                        </p>
                        <p class="text-muted">
                            <b>Complete Transparency:</b><br>With a client-centric approach, we keep you in the loop.
                            From availability checks to delivery timelines, our transparent processes ensure you're
                            always informed.
                        </p>
                        <p class="text-muted">
                            <b>Applications</b><br>Our PCB Component Sourcing expertise empowers a diverse range of
                            industries. Whether it's cutting-edge consumer electronics, avant-garde medical equipment,
                            dynamic aerospace projects, or next-gen communication devices, we source the beating heart
                            that powers these innovations.
                        </p>
                        <p class="text-muted">
                            <b>Elevate Your Projects with Premier Component Sourcing: </b><br>Behind every outstanding
                            PCB lies an array of perfectly sourced components. It's the precision in parts that elevates
                            performance. Ready to propel your projects to new heights with the best in component
                            sourcing? Join hands with our sourcing maestros today.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<section class="section" style="padding-bottom:10px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/products/Clipboard3.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>


<!-- Start Contact -->
<section class="section " id="getInquiry" style="padding-top:10px">

    <!--end container-->
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                <h4 style="text-align: center;" class="card-title"><b> ENQUIRE NOW</b></h4>
                <div class="card custom-form rounded border-0 shadow p-4">
                    <form method="post" name="myForm" (ngSubmit)="getintouch()" #myForm="ngForm">
                        <p id="error-msg" class="mb-0"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="name" id="name" type="text" class="form-control ps-5"
                                            placeholder="Name :" [(ngModel)]="getDetails.fullname" required>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="email" id="email" type="email" class="form-control ps-5"
                                            placeholder="Email :" [(ngModel)]="getDetails.emailaddress" required email>
                                        <div *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)"
                                            class="text-danger">Invalid email</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Phone No.</label>
                                    <div class="form-icon position-relative">
                                        <input name="phone" id="phone" class="form-control ps-5" placeholder="Phone No."
                                            [(ngModel)]="getDetails.contact" pattern="[0-9]{10}">
                                        <div *ngIf="myForm.controls['phone'].invalid && (myForm.controls['phone'].dirty || myForm.controls['phone'].touched)"
                                            class="text-danger">Invalid phone number (10 digits)</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Subject</label>
                                    <div class="form-icon position-relative">
                                        <input name="subject" id="subject" class="form-control ps-5"
                                            placeholder="subject :" [(ngModel)]="getDetails.subject">
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                            placeholder="Message :" [(ngModel)]="getDetails.message"
                                            required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-grid">
                                    <button type="submit" id="submit" name="send" class="btn btn-primary"
                                        [disabled]="myForm.invalid">Get
                                        in Touch</button>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </form>
                </div>
                <!--end custom-form-->
            </div>


            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->

<a href="https://wa.link/0l9zqv;" class="btn btn-icon  back-to-top" style="background-color: green;">

    <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
    <span class="h1 ">
        <i class="  mdi mdi-whatsapp large"> </i>
    </span>



</a>

<!-- Back to top -->