<section class="bg-half-170 d-table w-100 it-home" style="background: url('assets/images/product20.png') center center;"
    id="home">
    <!-- <div class="bg-overlay"></div> -->
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 col-12">
                <h3 class="text-white">Sharacircuits</h3>
                <h1 class="fw-bold text-white title-dark mt-2 mb-3"> Excellent Quality <br> PCB</h1>
                <p class="para-desc text-white">Super lightweight and excellent quality.</p>
                <!-- <div class="title-heading">
                    <h6 class="text-white-50">PCB Design & Assembly</h6>
                    <h1 class="fw-bold text-white title-dark mt-2 mb-3"> Excellent Quality <br> PCB</h1>
                    <p class="para-desc text-white-50">Super lightweight and excellent quality.</p>
                    <div class="mt-4 pt-2">
                        <a routerLink="/" href="javascript:void(0)" class="btn btn-primary m-1"><i class="uil uil-phone"></i> Get in
                            touch</a>
                    
                        <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
                            data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
                            <i-feather name="video" class="icons"></i-feather>
                        </a><small class="fw-bold text-uppercase text-white-50 title-dark align-middle ms-1">Watch
                            Now</small>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780"
                                    frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
                            </div>
                        </ng-template>
                    </div>
                </div> -->
            </div>
            <!--end col-->
            <P><br></P>
            <P><br></P>
            <P><br></P>
            <P><br></P>
            <P><br></P>
            <P><br></P>

            <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">



            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<section class="section"  style="padding-bottom:10px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/single/prod13.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/single/prod2.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/single/prod6.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h4 class="title mb-4">PCB Circuit Boards</h4>
                    <p class="text-muted para-desc mx-auto">Shara Circuits is a one stop PCB manufacturing vendor & PCBA
                        Assembly
                        Services, including the design, schematic, diagram, components sourcing, we strive to achieve
                        the most competetive
                        price in the most efficient way, good quality products and timely delivery to customers
                    </p>
                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-primary">Inquiry</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <!--END container-->
</section>

<!-- Start Terms & Conditions -->
<section class="section" style="padding:10px">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Manufacturing Capacity</h5>
                        <p class="text-muted">
                            We manufacture PCBS with Conventional Raw Material FR4 PCB and FR4-High TG-170 PCBs, 4
                            layers- 32 layers for Multilayer PCBs, Impedance Control PCBs, Anylayer HDI PCBs, Micro-via
                            Blind/Buried hole
                            PCBS, Solder mask with transparent ink for pcb boards, Carbon ink pcbs, Blue Peelable mask
                            for PCBs.
                            If you have any questions and more other information, feel free to reach us at our email
                            great@greatpcb.net
                            Over the years, we have invested in many advanced machines and built a vertical setup
                            infrastructure that combines modern technology and skilled professionals. Strive to become a
                            high-quality one-stop PCB&PCBA
                            service manufacturer for domestic and overseas customers.
                            The single-sided printed circuit board is usually a single-sided copper-clad laminate, and
                            the single-sided board has only one side of the circuit (the circuit layer is on one side,
                            and the plug-in parts are on the other
                            side).
                            Double-sided printed circuit boards are usually made of double-sided copper-clad laminates,
                            with two-layer circuit patterns of Component Side and Solder Side. During the processing of
                            the printed circuit board, the
                            hole walls of the vias are electroplated with copper layers to make the upper and lower
                            layers. Conduction, through the conductive hole through communication.
                            The multi-layer PCB is based on the double-sided board with the addition of the internal
                            power layer and the ground layer. Power and ground nets are primarily routed on the power
                            plane. A multi-layer board consists of
                            at least three circuit layers or more circuit layers, each substrate layer on the PCB is
                            conductive through vias, and the boards are bonded together using a lamination process, and
                            each There is an insulating material
                            between each circuit layer.
                            Therefore, the design of multi-layer PCB boards is basically the same as that of
                            double-sided boards. The key is how to optimize the routing of the internal electrical
                            layers to make the routing of the circuit board more
                            reasonable. Multifunctional to develop large-capacity and small-volume products.
                            With the continuous development of electronic technology and the wide application of
                            large-scale integrated circuits, multilayer PCBS are developing rapidly in the direction of
                            high-density and high-precision digitization.
                            More and more fine line width and line spacing and micro aperture penetration and blind
                            buried via hole, HDI technology to meet market demand.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->



<section class="section" style="padding:10px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="row">
                    <!-- Table Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4">
                                <h5 class="card-title">PCB Fabrication Capability</h5>
                                <div class="table-responsive bg-white shadow rounded">
                                    <table class="table mb-0 table-center">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="border-bottom">Engineer</th>
                                                <th scope="col" class="border-bottom">Item</th>
                                                <th scope="col" class="border-bottom">Manufacturing Capability</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Material</td>
                                                <td>Type</td>
                                                <td>FR-4, HIGH TG FR-4-TG170/TG180, CEM-
                                                    3,Halogen-Free, Rogers, Arlon, Taconic, Isola, PTFE,
                                                    Bergquist, Polyimide, Aluminum base, Copper
                                                    base, Heavy copper foil</td>
                                            </tr>
                                            <tr>
                                                <td>Production Type</td>
                                                <td>Surface treatment</td>
                                                <td>HASL, HASL lead-free, HAL, Flash gold, immersion
                                                    gold, OSP, Gold Finger Palting, Selective thick gold
                                                    plating, immersion silver, immersion tin, Carbon
                                                    ink,peelable mask</td>
                                            </tr>
                                            <tr>
                                                <td>Cut Lamination</td>
                                                <td>Work Panel Size</td>
                                                <td>Max:650mmx1200mm</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Table End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>


<!-- Start Contact -->
<section class="section " id="home" style="padding-top:10px">
   
    <!--end container-->
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                <h4 style="text-align: center;" class="card-title"><b> ENQUIRE NOW</b></h4>
                <div class="card custom-form rounded border-0 shadow p-4">
                   
                    <form method="post" name="myForm" onsubmit="return validateForm()">
                        <p id="error-msg" class="mb-0"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                        <input name="name" id="name" type="text" class="form-control ps-5"
                                            placeholder="Name :">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                        <input name="email" id="email" type="email" class="form-control ps-5"
                                            placeholder="Email :">
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">phone No.</label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                        <input name="subject" id="subject" class="form-control ps-5"
                                            placeholder="phone No. :">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Subject</label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                        <input name="subject" id="subject" class="form-control ps-5"
                                            placeholder="subject :">
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                            placeholder="Message :"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-grid">
                                    <button type="submit" id="submit" name="send" class="btn btn-primary">Send
                                        Message</button>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </form>
                </div>
                <!--end custom-form-->
            </div>


            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->

<a href="https://wa.link/0l9zqv;" class="btn btn-icon  back-to-top" style="background-color: green;">

    <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
    <span class="h1 ">
      <i class="  mdi mdi-whatsapp large"> </i>
    </span>
  
  
  
  </a>
  
  <!-- Back to top -->