import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-product1',
  templateUrl: './product1.component.html',
  styleUrls: ['./product1.component.css']
})
export class Product1Component {
  /***
   * nav bg class class set
   */
  //navClass = 'nav-light';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  
  constructor(private _data: DataService, public toastr: ToastrService) { }
  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    // autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };

  getDetails = {
    fullname: "",
    emailaddress: "",
    contact: "",
    subject: "",
    message: ""
  };
  data
  getintouch() {
    let payload = {
      fullname: this.getDetails.fullname,
      emailaddress: this.getDetails.emailaddress,
      contact: this.getDetails.contact,
      subject: this.getDetails.subject,
      message: this.getDetails.message
    }
    console.log('signin')
    this._data.login('website_request_quote', payload).then(res => {

      this.data=res
      this.getDetails.fullname=""
      this.getDetails.emailaddress=""
      this.getDetails.contact=""
      this.getDetails.subject=""
      this.getDetails.message=""
      if(this.data.status = "success"){
        this.toastr.success('Successfully sent Email');
      }
      // console.log(res)
    }).catch(err => {
      // console.log(this.data)
      console.log(err)
    })
  }
}
