<section class="section" style="padding-bottom:10px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/products/Clipboard.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h4 class="title mb-4">PCBA Assembly</h4>
                    <p class="text-muted para-desc mx-auto">Shara Circuits offers comprehensive custom PCB assembly
                        services tailored to your needs. Please forward your PCB files and BOM lists to
                        <b>info@sharacircuits.com</b>, and we'll promptly provide you with a quote!
                    </p>
                    <div class="mt-4" [ngxScrollTo]="'#getInquiry'">
                        <a href="javascript:void(0)" class="btn btn-primary">Inquiry</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <!--END container-->
</section>

<!-- Start Terms & Conditions -->
<section class="section" style="padding:10px">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Manufacturing Capacity</h5>
                        <p class="text-muted">
                            At Shara Circuits, we pride ourselves on delivering a comprehensive one-stop service. From
                            PCB design and layout to manufacturing, electronic component sourcing, assembly, testing,
                            packaging, and delivery – we have you covered every step of the way.
                        </p>
                        <p class="text-muted">
                            Electronic components serve as the building blocks for electronic devices, instruments, and
                            machinery. They're often composed of various parts, and they play pivotal roles across a
                            wide array of industries such as household appliances, radios, and measurement instruments.
                            Examples include transistors, capacitors, and even specific parts like hairsprings.
                        </p>
                        <p class="text-muted">
                            In the realm of electronics, several components play crucial roles in the functionality and
                            efficiency of devices. Resistors and capacitors are fundamental, serving to control current
                            and store energy. Inductors and potentiometers find their utility in circuits, aiding in
                            energy storage and voltage adjustment, respectively. Diodes, a common sight in many devices,
                            allow current to flow in just one direction. Semiconductors and tubes are pivotal,
                            manipulating the flow of electrical currents, while electroacoustic and laser devices
                            transform these electrical signals into sound and light. The importance of optoelectronic
                            devices and sensors cannot be overstated as they detect and control light. Power supplies
                            and switches are the heartbeats, channeling and directing the flow of electricity. Micro
                            motors and electronic transformers are tasked with the conversion of energy and instigation
                            of motion. At the core of modern electronics lie printed circuit boards and integrated
                            circuits, while special materials like piezoelectric crystals, quartz, ceramic magnetic
                            materials, and substrates for printed circuits add to the diversity. Lastly, electronic
                            adhesives and chemicals bind and process components together. Indeed, this intricate
                            tapestry of components offers just a brief insight into the vast world of electronics,
                            emphasizing the depth of complexity behind every electronic device in our daily lives.
                        </p>
                        <p class="text-muted">
                            <b>Q1. Why should you partner with us?</b><br>At Shara Circuits, we offer a comprehensive
                            one-stop electronic manufacturing service (EMS) solution. We seamlessly manage everything
                            from PCB production and PCBA assembly to parts procurement, DIP through-hole soldering, PCBA
                            testing, cabinet assembly, product verification, packaging, and logistics.
                        </p>
                        <p class="text-muted">
                            <b>Q2. How do we maintain quality standards?</b><br>Our products adhere to established
                            certification standards, including ISO9001, ISO14001, and UL, among others. We invite
                            prospective clients to place sample orders to assess our quality firsthand.
                        </p>
                        <p class="text-muted">
                            <b>Q3. What details are essential for PCB & PCBA quotes?</b><br>For PCB production, we
                            require the quantity, Gerber files, and technical specifications. For PCBA, we need PCB
                            files, BOM lists, stencil files, testing documents, and if available, PCBA samples for
                            cloning services. Details like a comprehensive BOM, approved spare parts, IC pre-programming
                            details, PCBA requirements, and any special needs like plastics, metals, or cables should
                            also be provided.
                        </p>
                        <p class="text-muted">
                            <b>Q4. Which file formats are acceptable for PCB and PCBA orders?</b><br>For PCBs, we accept
                            Gerber files (CAM350, RS-274-X full format) and PCB files from software like Protel 99SE,
                            DXP, PADS, AUTOCAD, and P-CAD 2001 PCB. BOMs can be sent in EXCEL, WORD, PDF, or TXT
                            formats.
                        </p>
                        <p class="text-muted">
                            <b>Q5. Is there a minimum order quantity (MOQ)?</b><br>We don't impose any MOQ for our PCB
                            or PCBA services, accommodating both small and large-volume orders with the same dedication.
                        </p>
                        <p class="text-muted">
                            <b>Q6. Can you expedite PCBA sample production?</b><br>Certainly! We can offer a 24-hour
                            express service for simple PCBA projects with limited components. Please share your BOM
                            lists for verification.
                        </p>
                        <p class="text-muted">
                            <b>Q7. How is the shipping cost determined?</b><br>Shipping costs are influenced by your
                            chosen shipping method, destination, package weight, and dimensions. To get a precise quote,
                            please share your specific needs.
                        </p>
                        <p class="text-muted">
                            <b>Q8. How do we test our PCB Boards and PCBA Products?</b><br>We employ a diverse range of
                            testing methods. For PCBs, we use fixture testing, fly probe testing, AOI, impedance tests,
                            and FQC. For PCBA, we leverage AOI, X-RAY, FQC, among other testing techniques.
                        </p>
                        <p class="text-muted">
                            <b>Q9. How long is the typical shipping duration?</b><br>Typically, for couriers like DHL,
                            UPS, FEDEX, TNT, and EMS, the shipping time ranges between 3-5 days.
                        </p>
                        <p class="text-muted">
                            <b>What exactly is an SMT stencil?</b><br>An SMT stencil is a specialized mold utilized in
                            the SMT (Surface Mount Technology) process. The predominant material used is stainless
                            steel, usually about 0.12mm in thickness, unless specific requirements dictate otherwise.
                            Its primary function is to serve as a template for applying solder paste onto the PCB
                            (Printed Circuit Board) during the initial SMT surface mounting stage.

                            For creating a stencil, either Gerber files are needed or the “TOP Paste layer” and “Bottom
                            Paste layer” should be provided.

                            The SMT procedure starts with screen-printing solder paste on the PCB boards, ensuring that
                            there's adequate solder paste on the circuit board pads. Subsequently, components are placed
                            on the board using automated equipment. Following this, the circuit board, now printed with
                            solder paste, undergoes heating in reflow soldering equipment, finalizing the soldering
                            process.
                        </p>
                        <p class="text-muted">
                            <b>Applications:</b><br>Our range of PCBA Assembly applications encompasses a broad
                            spectrum. These assemblies are integral to consumer electronics, USB TYPE-C Cables, Main
                            Control Boards, AC/DC power units, LED lighting solutions, household gadgets, power
                            regulation systems, chargers for electric vehicles, industrial controls, automotive
                            electronics, medical equipment, 5G communication tools, optical communication modules, and
                            various other sectors.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->


<!-- Start Contact -->
<section class="section " id="getInquiry" style="padding-top:10px">

    <!--end container-->
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                <h4 style="text-align: center;" class="card-title"><b> ENQUIRE NOW</b></h4>
                <div class="card custom-form rounded border-0 shadow p-4">
                    <form method="post" name="myForm" (ngSubmit)="getintouch()" #myForm="ngForm">
                        <p id="error-msg" class="mb-0"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="name" id="name" type="text" class="form-control ps-5"
                                            placeholder="Name :" [(ngModel)]="getDetails.fullname" required>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="email" id="email" type="email" class="form-control ps-5"
                                            placeholder="Email :" [(ngModel)]="getDetails.emailaddress" required email>
                                        <div *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)"
                                            class="text-danger">Invalid email</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Phone No.</label>
                                    <div class="form-icon position-relative">
                                        <input name="phone" id="phone" class="form-control ps-5" placeholder="Phone No."
                                            [(ngModel)]="getDetails.contact" pattern="[0-9]{10}">
                                        <div *ngIf="myForm.controls['phone'].invalid && (myForm.controls['phone'].dirty || myForm.controls['phone'].touched)"
                                            class="text-danger">Invalid phone number (10 digits)</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Subject</label>
                                    <div class="form-icon position-relative">
                                        <input name="subject" id="subject" class="form-control ps-5"
                                            placeholder="subject :" [(ngModel)]="getDetails.subject">
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                            placeholder="Message :" [(ngModel)]="getDetails.message"
                                            required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-grid">
                                    <button type="submit" id="submit" name="send" class="btn btn-primary"
                                        [disabled]="myForm.invalid">Get
                                        in Touch</button>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </form>
                </div>
                <!--end custom-form-->
            </div>


            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->

<a href="https://wa.link/0l9zqv;" class="btn btn-icon  back-to-top" style="background-color: green;">

    <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
    <span class="h1 ">
        <i class="  mdi mdi-whatsapp large"> </i>
    </span>



</a>

<!-- Back to top -->