<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home" style="background: url('assets/images/7.png') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h3 class="title text-white title-dark mb-0"> EVENTS </h3>
        </div>
      </div><!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <!-- <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
            <li class="breadcrumb-item"><a href="javascript:void(0)">Blog</a></li> -->
          <li class="breadcrumb-item active" aria-current="page">Events</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->


<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <div class="row">
          <div class="col-md-2 d-none d-md-block">
            <!-- <ul class="list-unstyled text-center sticky-bar social-icon social mb-0">
                <li class="mb-3 h6">Share</li>
                <li class="mt-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="mt-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="mt-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="mt-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul> -->
            <!--end icon-->
          </div>

          <div class="col-md-10">
            <!-- <p class="text-muted">This year, on October 22nd, we had the privilege of immersing ourselves in the radiant
              spirit of Dussehra Puja
              at our office. The air was infused with festivity and triumph as we commemorated the victory of good over
              evil.
              Our Dussehra Puja was a spectacular celebration that seamlessly blended tradition with contemporary flair.
            </p> -->
            <ul class="list-unstyled d-flex justify-content-between mt-4">
              <!-- <li class="list-inline-item user me-2"><a href="javascript:void(0)" class="text-muted"><i
                      class="uil uil-user text-dark"></i> Calvin Carlo</a></li> -->
              <li class="list-inline-item date text-muted"><i class="uil uil-calendar-alt text-dark"></i> 22nd Oct 2023
              </li>
            </ul>

            <img src="assets/images/blog/dussehra.jpg" class="img-fluid rounded-md shadow" alt="">

            <h5 class="mt-4">Dussehra and Ayudha Pooja at Office 2023</h5>

            <p class="text-muted">This year, on October 22nd, we had the privilege of immersing ourselves in the radiant
              spirit of Dussehra Puja
              at our office. The air was infused with festivity and triumph as we commemorated the victory of good over
              evil.
              Our Dussehra Puja was a spectacular celebration that seamlessly blended tradition with contemporary flair.
            </p>

            <!-- <h5 class="mt-4">Comments :</h5> -->
            <!--   
              <ul class="media-list list-unstyled mb-0">
                <li class="mt-4">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <a class="pe-3" href="javascript:void(0)">
                        <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow"
                          alt="img">
                      </a>
                      <div class="flex-1 commentor-detail">
                        <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading">Lorenzo Peterson</a>
                        </h6>
                        <small class="text-muted">15th August, 2019 at 01:25 pm</small>
                      </div>
                    </div>
                    <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                  </div>
                  <div class="mt-3">
                    <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem
                      Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                  </div>
                </li>
  
                <li class="mt-4">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <a class="pe-3" href="javascript:void(0)">
                        <img src="assets/images/client/02.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow"
                          alt="img">
                      </a>
                      <div class="flex-1 commentor-detail">
                        <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading text-dark">Tammy Camacho</a>
                        </h6>
                        <small class="text-muted">15th August, 2019 at 05:44 pm</small>
                      </div>
                    </div>
                    <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                  </div>
                  <div class="mt-3">
                    <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem
                      Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                  </div>
                </li>
  
                <li class="mt-4">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <a class="pe-3" href="javascript:void(0)">
                        <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-md-sm rounded-circle shadow"
                          alt="img">
                      </a>
                      <div class="flex-1 commentor-detail">
                        <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading text-dark">Tammy Camacho</a>
                        </h6>
                        <small class="text-muted">16th August, 2019 at 03:44 pm</small>
                      </div>
                    </div>
                    <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                  </div>
                  <div class="mt-3">
                    <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of Lorem
                      Ipsum available, but the majority have suffered alteration in some form, by injected humour "</p>
                  </div>
  
                  <ul class="list-unstyled ps-4 ps-md-5 sub-comment">
                    <li class="mt-4">
                      <div class="d-flex justify-content-between">
                        <div class="d-flex align-items-center">
                          <a class="pe-3" href="javascript:void(0)">
                            <img src="assets/images/client/01.jpg"
                              class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                          </a>
                          <div class="flex-1 commentor-detail">
                            <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading">Lorenzo
                                Peterson</a></h6>
                            <small class="text-muted">17th August, 2019 at 01:25 pm</small>
                          </div>
                        </div>
                        <a [ngxScrollTo]="'#home'" class="text-muted"><i class="mdi mdi-reply"></i> Reply</a>
                      </div>
                      <div class="mt-3">
                        <p class="text-muted fst-italic p-3 bg-light rounded">" There are many variations of passages of
                          Lorem Ipsum available, but the majority have suffered alteration in some form, by injected
                          humour "</p>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul> -->

            <!-- <h5 class="mt-4">Leave A Comment :</h5> -->
            <!--   
              <form class="mt-3">
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Your Comment</label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                        <textarea id="message" placeholder="Your Comment" rows="5" name="message"
                          class="form-control ps-5" required=""></textarea>
                      </div>
                    </div>
                  </div> -->
            <!--end col-->

            <!-- <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">Name <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input id="name" name="name" type="text" placeholder="Name" class="form-control ps-5" required="">
                      </div>
                    </div>
                  </div> -->
            <!--end col-->

            <!-- <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">Your Email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input id="email" type="email" placeholder="Email" name="email" class="form-control ps-5"
                          required="">
                      </div>
                    </div>
                  </div> -->
            <!--end col-->

            <!-- <div class="col-md-12">
                    <div class="send d-grid">
                      <button type="submit" class="btn btn-primary">Send Message</button>
                    </div>
                  </div> -->
            <!--end col-->
            <!-- </div> -->
            <!--end row-->
            <!-- </form> -->
            <!--end form-->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->