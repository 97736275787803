<section class="section" style="padding-bottom:10px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/products/Clipboard4.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h4 class="title mb-4">PCB Prototyping</h4>
                    <p class="text-muted para-desc mx-auto">
                        Every groundbreaking electronic product begins as a prototype a tangible representation of an
                        idea. Bridging the gap between imagination and reality is our state-of-the-art PCB prototyping
                        service. Discover the path to flawless final products with our unmatched prototyping expertise.
                    </p>
                    <div class="mt-4" [ngxScrollTo]="'#getInquiry'">
                        <a href="javascript:void(0)" class="btn btn-primary">Inquiry</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
    <!--END container-->
</section>

<!-- Start Terms & Conditions -->
<section class="section" style="padding:10px">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Why Choose Our PCB Prototyping Services?</h5>
                        <p class="text-muted">
                            <b>Rapid Turnaround: </b><br>In the dynamic realm of electronics, speed is of the essence.
                            Our services guarantee swift prototyping without compromising on quality, ensuring your
                            innovative ideas come to life quickly.
                        </p>
                        <p class="text-muted">
                            <b>Precision Engineering:</b><br>Leveraging cutting-edge technology and a team of seasoned
                            experts, we ensure that every prototype mirrors your design specifications with impeccable
                            accuracy.
                        </p>
                        <p class="text-muted">
                            <b>Scalable Solutions: </b><br>Whether you're looking to create a single prototype or
                            require small batch production for testing, our services are scalable to meet diverse needs.
                        </p>
                        <p class="text-muted">
                            <b>Cost-Efficient Processes:</b><br>Prototyping doesn't have to break the bank. Our
                            optimized processes ensure cost-effectiveness, allowing innovators to iterate and refine
                            without financial constraints.
                        </p>
                        <p class="text-muted">
                            <b>Real-World Testing:</b><br>A prototype is more than just a proof of concept. We subject
                            our prototypes to real-world conditions, ensuring they're not just functional but also
                            durable and reliable.
                        </p>
                        <p class="text-muted">
                            <b>Applications</b><br>Our PCB prototyping solutions pave the way for innovations across a
                            spectrum of industries. Be it the next-gen wearable tech, a revolutionary medical device, an
                            advanced aerospace component, or a game-changing consumer gadget, our prototypes form the
                            backbone of countless breakthroughs.
                        </p>
                        <p class="text-muted">
                            <b>Forge the Future with Flawless Prototypes</b><br>Prototyping is the cornerstone of
                            innovation—a tangible first step towards a transformative end product. With our unparalleled
                            expertise in PCB prototyping, transform your visions into tangible, testable, and top-tier
                            prototypes. Ready to make the leap from concept to creation? Engage with our prototyping
                            maestros today.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->


<!-- Start Contact -->
<section class="section " id="getInquiry" style="padding-top:10px">

    <!--end container-->
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                <h4 style="text-align: center;" class="card-title"><b> ENQUIRE NOW</b></h4>
                <div class="card custom-form rounded border-0 shadow p-4">

                    <form method="post" name="myForm" (ngSubmit)="getintouch()" #myForm="ngForm">
                        <p id="error-msg" class="mb-0"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="name" id="name" type="text" class="form-control ps-5"
                                            placeholder="Name :" [(ngModel)]="getDetails.fullname" required>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="email" id="email" type="email" class="form-control ps-5"
                                            placeholder="Email :" [(ngModel)]="getDetails.emailaddress" required email>
                                        <div *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)"
                                            class="text-danger">Invalid email</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Phone No.</label>
                                    <div class="form-icon position-relative">
                                        <input name="phone" id="phone" class="form-control ps-5" placeholder="Phone No."
                                            [(ngModel)]="getDetails.contact" pattern="[0-9]{10}">
                                        <div *ngIf="myForm.controls['phone'].invalid && (myForm.controls['phone'].dirty || myForm.controls['phone'].touched)"
                                            class="text-danger">Invalid phone number (10 digits)</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Subject</label>
                                    <div class="form-icon position-relative">
                                        <input name="subject" id="subject" class="form-control ps-5"
                                            placeholder="subject :" [(ngModel)]="getDetails.subject">
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                            placeholder="Message :" [(ngModel)]="getDetails.message"
                                            required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-grid">
                                    <button type="submit" id="submit" name="send" class="btn btn-primary"
                                        [disabled]="myForm.invalid">Get
                                        in Touch</button>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </form>
                </div>
                <!--end custom-form-->
            </div>


            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->

<a href="https://wa.link/0l9zqv;" class="btn btn-icon  back-to-top" style="background-color: green;">

    <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
    <span class="h1 ">
        <i class="  mdi mdi-whatsapp large"> </i>
    </span>



</a>

<!-- Back to top -->