<section class="section" style="padding-bottom:10px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/products/Clipboard7.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h4 class="title mb-4">Product Development: Bringing Innovations to Life</h4>
                    <p class="text-muted para-desc mx-auto">
                        Navigating the complex journey from a mere idea to a market-ready product requires expertise,
                        precision, and a passion for innovation. Our product development services bridge the gap between
                        vision and reality, transforming groundbreaking concepts into tangible products that resonate
                        with users.
                    </p>
                    <div class="mt-4" [ngxScrollTo]="'#getInquiry'">
                        <a href="javascript:void(0)" class="btn btn-primary">Inquiry</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <!--END container-->
</section>

<!-- Start Terms & Conditions -->
<section class="section" style="padding:10px">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Why Venture with Us on Your Product Development Odyssey?</h5>
                        <p class="text-muted">
                            <b>Conceptual Clarity:</b><br>Our team collaborates closely with you, refining and
                            crystallizing your idea to ensure it aligns perfectly with market needs and trends.
                        </p>
                        <p class="text-muted">
                            <b>Integrated Approach:</b><br>We offer a holistic product development journey, from initial
                            ideation, prototyping, testing, to final production. Our end-to-end solutions ensure
                            seamless progression at every stage.
                        </p>
                        <p class="text-muted">
                            <b>Cutting-Edge Technologies:</b><br>In a constantly evolving tech landscape, we harness the
                            latest tools and methodologies, ensuring your product is not just current, but future-ready.
                        </p>
                        <p class="text-muted">
                            <b>User-Centric Design:</b><br>Understanding the end user is paramount. Our design thinking
                            approach ensures the end product is intuitive, functional, and addresses genuine user needs.
                        </p>
                        <p class="text-muted">
                            <b>Quality First:</b><br>Every phase of our product development cycle undergoes rigorous
                            quality checks, ensuring the end product stands up to, and exceeds, market standards.
                        </p>
                        <p class="text-muted">
                            <b>Applications</b><br>Our product development expertise spans various sectors. From
                            state-of-the-art wearable tech, innovative home automation systems, to transformative
                            healthcare solutions, our touch can be seen in products that are pushing boundaries and
                            setting new standards.
                        </p>
                        <p class="text-muted">
                            <b>Embark on a Product Development Journey Like No Other:</b><br>Innovation isn't just about
                            new ideas; it's about making those ideas work. With our dedicated team by your side, see
                            your visions come to life, ready to captivate markets and enrich lives. Dive into a product
                            development experience that prioritizes your vision, values quality, and celebrates
                            innovation.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->



<!-- Start Contact -->
<section class="section " id="getInquiry" style="padding-top:10px">

    <!--end container-->
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                <h4 style="text-align: center;" class="card-title"><b> ENQUIRE NOW</b></h4>
                <div class="card custom-form rounded border-0 shadow p-4">
                    <form method="post" name="myForm" (ngSubmit)="getintouch()" #myForm="ngForm">
                        <p id="error-msg" class="mb-0"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="name" id="name" type="text" class="form-control ps-5"
                                            placeholder="Name :" [(ngModel)]="getDetails.fullname" required>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="email" id="email" type="email" class="form-control ps-5"
                                            placeholder="Email :" [(ngModel)]="getDetails.emailaddress" required email>
                                        <div *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)"
                                            class="text-danger">Invalid email</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Phone No.</label>
                                    <div class="form-icon position-relative">
                                        <input name="phone" id="phone" class="form-control ps-5" placeholder="Phone No."
                                            [(ngModel)]="getDetails.contact" pattern="[0-9]{10}">
                                        <div *ngIf="myForm.controls['phone'].invalid && (myForm.controls['phone'].dirty || myForm.controls['phone'].touched)"
                                            class="text-danger">Invalid phone number (10 digits)</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Subject</label>
                                    <div class="form-icon position-relative">
                                        <input name="subject" id="subject" class="form-control ps-5"
                                            placeholder="subject :" [(ngModel)]="getDetails.subject">
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                            placeholder="Message :" [(ngModel)]="getDetails.message"
                                            required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-grid">
                                    <button type="submit" id="submit" name="send" class="btn btn-primary"
                                        [disabled]="myForm.invalid">Get
                                        in Touch</button>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </form>
                </div>
                <!--end custom-form-->
            </div>


            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->

<a href="https://wa.link/0l9zqv;" class="btn btn-icon  back-to-top" style="background-color: green;">

    <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
    <span class="h1 ">
        <i class="  mdi mdi-whatsapp large"> </i>
    </span>



</a>

<!-- Back to top -->