import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper';
@Component({
  selector: 'app-product9',
  templateUrl: './product9.component.html',
  styleUrls: ['./product9.component.css']
})
export class Product9Component {
  /***
   * nav bg class class set
   */
  navClass = 'nav-light';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  

  customOptions: OwlOptions = {
    // loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    // autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };
}
