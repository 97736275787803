<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 it-home" style="background: url('assets/images/it/bg1.jpg') center center;"
  id="home">
  <!-- <div class="bg-overlay"></div> -->
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6 col-12">
        <div class="title-heading">
          <h1 class="text-white">Shara Circuits</h1>
          <h2 class="fw-bold text-white title-dark mt-2 mb-3"> From concept to creation </h2>
          <!-- <p class="para-desc text-white">Super lightweight and excellent quality.</p> -->

        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">



      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->


<!-- <section class="section-two bg-light" id="bookroom">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <form class="rounded p-4 mt-4">
                    <div class="row text-start">
                        <div class="col-lg-5 col-md-4">
                            <div class="mb-0">
                                <label>dimentions (mm)</label>
                                <div class="row">
                                    <div class="col">
                                        <input type="text" class="form-control" required placeholder="Keywords">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" required placeholder="Keywords">
                                    </div>
                                </div>
                            </div>
                        </div>
                       

                        <div class="col-lg-7 col-md-8">
                            <div class="row align-items-center">
                                <div class="col-md-4 mt-4 mt-sm-0">
                                    <div class="mb-0">
                                        <label>Layers</label>
                                        <select class="form-control custom-select">
                                            <option selected="">Layer 2</option>mm
                                            <option value="AF">Layer 4</option>
                                           
                                        </select>
                                    </div>
                                </div>
                            

                                <div class="col-md-4 mt-4 mt-sm-0">
                                    <div class="mb-0">
                                        <label>Quantity</label>
                                        <input type="text" class="form-control" required placeholder="Quantity">
                                    </div>
                                </div>
                          

                                <div class="col-md-4 mt-4 mt-sm-0">
                                    <div class="d-grid">
                                        <label> <br></label>
                                        <input type="submit" id="search" name="search" class="searchbtn btn btn-primary"
                                            value="Search">
                                    </div>
                                </div>
                              
                            </div>
                       
                        </div>
                   
                    </div>
             
                </form>
            </div>
        
        </div>
   
    </div>
   
</section> -->
<section class="section bg-light">
  <!-- Start Client -->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <!-- <h2 class="title mb-4">Instant Quote Feature Launching Soon!</h2> -->
          <h2 class="title mb-4">Our self-service request Quote platform is live now, along with
            real-time order tracking.
          </h2>
          <div class="text-center mt-3">
            <button class="btn btn-primary mr-1" routerLink="/Requestquote">Request Quote</button>
          </div>
          <div class="text-center mt-3">
            <button class="btn btn-primary mr-1" routerLink="/track-order">Track Order</button>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->


    <!--end row-->
  </div>

  <!--end container-->
  <!-- End Blog -->
</section>

<!-- Courses Start -->
<section class="section" id="courses">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">PRODUCTS</h4>
          <!-- <p class="text-muted para-desc mx-auto mb-0">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div routerLink="/pcb-fabrication" class="col-lg-4 col-md-6 col-12 mt-4 pt-2">

        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/prod7.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3"> <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">PCB Fabricating</a></h5>
            <!-- <div class="rating">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item">5 Star (3 <i class="uil uil-user text-muted small"></i>)</li>
                </ul>
              </div> -->
            <!-- <div class="fees d-flex justify-content-between">
                <ul class="list-unstyled mb-0 numbers">
                  <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                  <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
                </ul>
                <h4><span class="h6">$</span>75</h4>
              </div> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div routerLink="/pcb-assembly" class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/prod2.png" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3"> <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">PCB Assembly</a></h5>
            <!-- <div class="rating">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star-outline h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item">3.99 Star (11 <i class="uil uil-user text-muted small"></i>)</li>
                </ul>
              </div>
              <div class="fees d-flex justify-content-between">
                <ul class="list-unstyled mb-0 numbers">
                  <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                  <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
                </ul>
                <h4><span class="h6">$</span>150</h4>
              </div> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div routerLink="/pcb-design" class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/prod10.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3"> <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">PCB Design</a></h5>
            <!-- <div class="rating">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star-half h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item">4.7 Star (9 <i class="uil uil-user text-muted small"></i>)</li>
                </ul>
              </div> -->
            <!-- <div class="fees d-flex justify-content-between">
                <ul class="list-unstyled mb-0 numbers">
                  <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                  <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
                </ul>
                <h4><span class="h6">$</span>175</h4>
              </div> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div routerLink="/pcb-testing-services" class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/prod15.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3"> <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">PCB Testing</a></h5>
            <!-- <div class="rating">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item">5 Star (3 <i class="uil uil-user text-muted small"></i>)</li>
                </ul>
              </div>
              <div class="fees d-flex justify-content-between">
                <ul class="list-unstyled mb-0 numbers">
                  <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                  <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
                </ul>
                <h4><span class="h6">$</span>22</h4>
              </div> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div routerLink="/pcb-prototype" class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/prod8.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3"> <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">PCB Prototyping</a></h5>
            <!-- <div class="rating">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item">5 Star (3 <i class="uil uil-user text-muted small"></i>)</li>
                </ul>
              </div>
              <div class="fees d-flex justify-content-between">
                <ul class="list-unstyled mb-0 numbers">
                  <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                  <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
                </ul>
                <h4><span class="h6">$</span>100</h4>
              </div> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div routerLink="/metal-core-pcb" class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/prod19.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3"> <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Metal PCB</a></h5>
            <!-- <div class="rating">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star-outline h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item">4 Star (2 <i class="uil uil-user text-muted small"></i>)</li>
                </ul>
              </div>
              <div class="fees d-flex justify-content-between">
                <ul class="list-unstyled mb-0 numbers">
                  <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                  <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
                </ul>
                <h4><span class="h6">$</span>110</h4>
              </div> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div routerLink="/pcb-component-sourcing" class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/prod17.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3"> <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Component sourcing </a></h5>
            <!-- <div class="rating">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star-half h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item">4.5 Star (8 <i class="uil uil-user text-muted small"></i>)</li>
                </ul>
              </div>
              <div class="fees d-flex justify-content-between">
                <ul class="list-unstyled mb-0 numbers">
                  <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                  <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
                </ul>
                <h4><span class="h6">$</span>149</h4>
              </div> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div routerLink="/product-development" class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/prod16.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3"> <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Product Development</a></h5>
            <!-- <div class="rating">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star h5 mb-0 text-warning"></i></li>
                  <li class="list-inline-item">5 Star (3 <i class="uil uil-user text-muted small"></i>)</li>
                </ul>
              </div>
              <div class="fees d-flex justify-content-between">
                <ul class="list-unstyled mb-0 numbers">
                  <li><i class="uil uil-graduation-cap text-muted"></i> 30 Students</li>
                  <li><i class="uil uil-notebooks text-muted"></i> 5 Lession</li>
                </ul>
                <h4><span class="h6">$</span>135</h4>
              </div> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <!-- <div routerLink="/product9" class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
        <div class="card blog blog-primary overflow-hidden rounded shadow border-0">
          <div class="position-relative d-block overflow-hidden">
            <img src="assets/images/course/prod6.jpg" class="img-fluid rounded-top mx-auto" alt="">
            <div class="overlay-work bg-dark"></div>
            <a href="javascript:void(0)" class="h6 preview bottom-0 end-0 me-4 mb-3"> <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>

          <div class="card-body">
            <h5><a href="javascript:void(0)" class="title text-dark">Product9</a></h5>
  
          </div>
        </div>
      </div> -->
      <!--end col-->


    </div>
    <!--end row-->
  </div>
  <!--end container-->


</section>
<!--end section-->
<!-- Courses End -->






<!-- Back to top -->

<a href="https://wa.link/zauw69" class="btn btn-icon  back-to-top" style="background-color: green;">

  <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
  <span class="h1 ">
    <i class="  mdi mdi-whatsapp large"> </i>
  </span>



</a>




<!-- Subscribe popup End -->


<!-- Back to top -->


<!-- <ng-template class="theme-modal" #newsletter let-modal>
  <div class="modal-lg">
    <div class="modal-content ">
      <div class="modal-body modal7">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12">
              <div class="modal-bg">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="offer-content">
                  <h2>Hurry 50% off on every online order</h2>
                  <form
                    action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
                    class="auth-form needs-validation" method="post" id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form" target="_blank">
                    <div class="form-group mx-sm-3">
                      <button type="submit" class="btn btn-solid" id="mc-submit" routerLink="/Requestquote"
                        (click)="modal.dismiss('Cross click')">Request Quote</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->

<div *ngIf="isVisible" class="offer-box">
  <button type="button" class="close" aria-label="Close" (click)="hideOfferBox()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="offer-content">
    <div class="image-container">
      <img src="assets/images/shara_sale.png" alt="PCB Sale">
      <!-- <div class="sale-alert">Big Sale Alert!</div> -->
    </div>
    <p>Get your PCB order now with 50% off! Quality guaranteed, don't miss out!</p>
    <button class="btn btn-solid" routerLink="/Requestquote">ORDER NOW!</button>
  </div>
</div>

