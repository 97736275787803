import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-page-contact-two',
  templateUrl: './page-contact-two.component.html',
  styleUrls: ['./page-contact-two.component.css']
})

/**
 * Page Contact-Two Component
 */
export class PageContactTwoComponent implements OnInit {

  // navClass = 'nav-light';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  constructor(private modalService: NgbModal,private _data: DataService,public toastr: ToastrService) { }

  ngOnInit(): void {
  }

  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }

  getDetails = {
    fullname: "",
    emailaddress: "",
    contact: "",
    subject: "",
    message: ""
  };
  data
  getintouch() {
    let payload = {
      fullname: this.getDetails.fullname,
      emailaddress: this.getDetails.emailaddress,
      contact: this.getDetails.contact,
      subject: this.getDetails.subject,
      message: this.getDetails.message
    }
    console.log('signin')
    this._data.login('website_request_quote', payload).then(res => {

      this.data=res
      this.getDetails.fullname=""
      this.getDetails.emailaddress=""
      this.getDetails.contact=""
      this.getDetails.subject=""
      this.getDetails.message=""
      if(this.data.status = "success"){
        this.toastr.success('Successfully sent Email');
      }
      // console.log(res)
    }).catch(err => {
      // console.log(this.data)
      console.log(err)
    })
  }
}