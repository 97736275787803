<section class="section" style="padding-bottom:10px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/products/Clipboard2.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h4 class="title mb-4">Unlocking Reliability with Our PCB Testing Services</h4>
                    <p class="text-muted para-desc mx-auto">
                        Every electronic marvel is a culmination of innovation, design, and rigorous testing. At the
                        heart of ensuring the reliability and efficiency of these innovations is the process of PCB
                        testing. Dive into the realm of assurance with our top-tier PCB testing services.
                    </p>
                    <div class="mt-4" [ngxScrollTo]="'#getInquiry'">
                        <a href="javascript:void(0)" class="btn btn-primary">Inquiry</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <!--END container-->
</section>

<!-- Start Terms & Conditions -->
<section class="section" style="padding:10px">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Why Our PCB Testing Services Stand Out</h5>
                        <p class="text-muted">
                            <b>Precision in Every Test:</b><br>The accuracy of a PCB test can determine the success of
                            an electronic product. We utilize advanced testing equipment and methodologies to ensure
                            that every circuit, trace, and component meets the specified standards.
                        </p>
                        <p class="text-muted">
                            <b>End-to-End Testing Solutions:</b><br>From functional testing to in-circuit tests, from
                            flying probe tests to thermal cycling, our comprehensive suite ensures every potential flaw
                            is identified and rectified.
                        </p>
                        <p class="text-muted">
                            <b>Experience Across Domains: </b><br>With vast experience spanning multiple industries, we
                            understand the unique challenges and requirements each sector presents. Whether it's
                            medical, aerospace, telecommunications, or consumer electronics, we've got you covered.
                        </p>
                        <p class="text-muted">
                            <b>Real-Time Fault Detection:</b><br>Our advanced diagnostic tools and software help in
                            real-time fault detection, ensuring timely identification and correction of potential
                            issues.
                        </p>
                        <p class="text-muted">
                            <b>Custom Testing Protocols:</b><br>Recognizing the unique nature of every PCB design, we
                            develop custom testing protocols tailored to your specific product, ensuring a thorough and
                            focused testing approach.
                        </p>
                        <p class="text-muted">
                            <b>Applications</b><br>Our PCB testing services are pivotal across various industries. From
                            ensuring the longevity of a home appliance to guaranteeing the precision of critical medical
                            equipment or enhancing the performance of automotive electronics, our testing methodologies
                            are trusted by innovators worldwide.
                        </p>
                        <p class="text-muted">
                            <b>Guarantee Excellence with Us</b><br>In the intricate world of electronics, the difference
                            between success and failure often lies in the robustness of testing. Entrust us with this
                            crucial phase to ensure that your PCB not only meets but exceeds industry standards. To
                            delve deeper into our testing solutions and embark on a journey of guaranteed quality,
                            connect with our dedicated team today.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->


<!-- Start Contact -->
<section class="section " id="getInquiry" style="padding-top:10px">

    <!--end container-->
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                <h4 style="text-align: center;" class="card-title"><b> ENQUIRE NOW</b></h4>
                <div class="card custom-form rounded border-0 shadow p-4">

                    <form method="post" name="myForm" (ngSubmit)="getintouch()" #myForm="ngForm">
                        <p id="error-msg" class="mb-0"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="name" id="name" type="text" class="form-control ps-5"
                                            placeholder="Name :" [(ngModel)]="getDetails.fullname" required>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="email" id="email" type="email" class="form-control ps-5"
                                            placeholder="Email :" [(ngModel)]="getDetails.emailaddress" required email>
                                        <div *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)"
                                            class="text-danger">Invalid email</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Phone No.</label>
                                    <div class="form-icon position-relative">
                                        <input name="phone" id="phone" class="form-control ps-5" placeholder="Phone No."
                                            [(ngModel)]="getDetails.contact" pattern="[0-9]{10}">
                                        <div *ngIf="myForm.controls['phone'].invalid && (myForm.controls['phone'].dirty || myForm.controls['phone'].touched)"
                                            class="text-danger">Invalid phone number (10 digits)</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Subject</label>
                                    <div class="form-icon position-relative">
                                        <input name="subject" id="subject" class="form-control ps-5"
                                            placeholder="subject :" [(ngModel)]="getDetails.subject">
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                            placeholder="Message :" [(ngModel)]="getDetails.message"
                                            required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-grid">
                                    <button type="submit" id="submit" name="send" class="btn btn-primary"
                                        [disabled]="myForm.invalid">Get
                                        in Touch</button>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </form>
                </div>
                <!--end custom-form-->
            </div>


            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->

<a href="https://wa.link/0l9zqv;" class="btn btn-icon  back-to-top" style="background-color: green;">

    <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
    <span class="h1 ">
        <i class="  mdi mdi-whatsapp large"> </i>
    </span>



</a>

<!-- Back to top -->