<section class="section" style="padding-bottom:10px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/products/Clipboard5.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h4 class="title mb-4">Unveiling the Power of Metal PCBs: Your Robust Solution for Extreme
                        Environments</h4>
                    <p class="text-muted para-desc mx-auto">
                        Shara Circuit Pvt Ltd specialize in the art and science of crafting metal PCBs that are
                        engineered to excel in demanding environments
                        ensuring your electronics perform reliably whether you're designing LED lighting systems, power
                        electronics, automative components
                        or aerospace equipment our metal PCBs provide the robust foundation you need to achieve
                        excellence.
                    </p>
                    <!-- <p class="text-muted para-desc mx-auto">
                        In an era where durability and performance stand paramount, metal-based PCBs emerge as the
                        heroes of the electronic world. These powerhouses are specially designed to combat harsh
                        conditions while offering unparalleled heat dissipation. Dive deep into our state-of-the-art
                        metal PCB services and discover the perfect amalgamation of strength and sophistication.
                    </p> -->
                    <div class="mt-4" [ngxScrollTo]="'#getInquiry'">
                        <a href="javascript:void(0)" class="btn btn-primary">Inquiry</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <!--END container-->
</section>

<!-- Start Terms & Conditions -->
<section class="section" style="padding:10px">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Why Opt for Our Metal PCB Solutions?</h5>
                        <p class="text-muted">
                            <b>Superior Heat Dissipation: </b><br>Traditional PCBs can sometimes fall short in
                            dissipating heat effectively. Our metal PCBs, primarily built with aluminum or copper bases,
                            ensure rapid and uniform heat transfer, prolonging the life of your components.
                        </p>
                        <p class="text-muted">
                            <b>Enhanced Durability: </b><br>Crafted for challenging environments, our metal PCBs stand
                            resilient against mechanical stresses, offering a prolonged operational life.
                        </p>
                        <p class="text-muted">
                            <b>Compact & Lightweight: </b><br>Despite their strength, our metal PCBs are streamlined and
                            lightweight, ensuring they seamlessly fit into space-constrained applications.
                        </p>
                        <p class="text-muted">
                            <b>Cost-Effective: </b><br>Advanced doesn't always mean expensive. With efficient
                            manufacturing processes, our metal PCB solutions are pocket-friendly, ensuring you get
                            top-tier quality without straining your budget.
                        </p>
                        <p class="text-muted">
                            <b>Eco-Friendly:</b><br>With a commitment to sustainability, our metal PCBs are recyclable,
                            aligning with eco-conscious practices and reducing your carbon footprint.
                        </p>
                        <p class="text-muted">
                            <b>Applications</b><br>Metal PCBs are the silent stalwarts behind a myriad of industries.
                            From high-intensity LED lighting systems, automotive systems, and power converters, to
                            robust industrial machinery, aerospace components, and cutting-edge medical devices, our
                            metal PCBs provide the sturdy foundation for products that defy the odds.
                        </p>
                        <p class="text-muted">
                            <b>Forge Ahead with Metal Might: </b><br>In the realm of PCBs, metal variants are the
                            titans. They blend the electrical prowess of traditional PCBs with the physical strength of
                            metals, ensuring your electronics function seamlessly even in the most demanding conditions.
                            Ready to elevate your projects with the metal advantage? Connect with our metal PCB
                            specialists today.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->



<section class="section" style="padding:10px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="row">
                    <!-- Table Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4">
                                <h5 class="card-title">PCB Fabrication Capability</h5>
                                <div class="table-responsive bg-white shadow rounded">
                                    <table class="table mb-0 table-center text-muted">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="border-bottom">Item</th>
                                                <th scope="col" class="border-bottom">Manufacturing Capability</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Material base</td>
                                                <td>Aluminium core PCB, Cu core PCB, Fe base PCB, Ceramic PCB etc
                                                    Special Material(5052,6061,6063)</td>
                                            </tr>
                                            <tr>
                                                <td>Surface Treatment</td>
                                                <td>HASL, HASL L/FENIG, PlatingNi/Au,NiPdAu, Plating Sliver, Immersion
                                                    Sliver, Immersion Tin,0SP,Flux</td>
                                            </tr>
                                            <tr>
                                                <td>No.of Layer</td>
                                                <td>Single-sided, Double-sided,4 layer Aluminium Base PCB</td>
                                            </tr>
                                            <tr>
                                                <td>Board Size</td>
                                                <td>Max: 1200*550MM / min: 5*5MM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Conductor Width/spacing</td>
                                                <td>0.15MM/0.15MM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Warp and Twist</td>
                                                <td>
                                                    <=0.75 % </td>
                                            </tr>
                                            <tr>
                                                <td>Board Thickness</td>
                                                <td> 0.6MM-6.0MM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Copper Thickness</td>
                                                <td> 35UM, 70UM. 105UM. 140UM. 210UM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Remain Thickness Tolerance</td>
                                                <td> +-0.1MM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Hole Wall Copper Thickness</td>
                                                <td> >=18UM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>PTH Hole Dia. Tolerance</td>
                                                <td> +-0.076MM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>NPTH Tolerance</td>
                                                <td> +-0.05MM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Min Hole Size</td>
                                                <td> 0.2mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Min. Punch hole Dimension</td>
                                                <td> 0.8MMM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Min. Punch Slot Dimension</td>
                                                <td> 0.8MM*0.8MM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Hole Position Deviation</td>
                                                <td> +-0.076mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Outline Tolerance</td>
                                                <td> +-10%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>V-cut</td>
                                                <td> 30°/45°/60°
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Min BGA Pitch PAD</td>
                                                <td> 20mil
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Min. legend Type</td>
                                                <td> 0.15MM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Soldemask Layer Min Bridge width</td>
                                                <td> 5 mil
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Soldemask film Min. Thickness</td>
                                                <td> 10 mil
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>V-CUT angular deviation</td>
                                                <td> 5 Angular
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>V-CUT Board Thickness</td>
                                                <td> 0.6MM-3.2MM
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>E-test Voltage</td>
                                                <td> 50-250V
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Permitivity</td>
                                                <td> 2.1~10.0
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Thermal conductivity</td>
                                                <td> 0.8-8W/MK
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div><!--end col-->
                    <!-- Table End -->
                </div><!--end row-->
            </div><!--end col-->
        </div>
    </div>
</section>


<!-- Start Contact -->
<section class="section " id="getInquiry" style="padding-top:10px">

    <!--end container-->
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                <h4 style="text-align: center;" class="card-title"><b> ENQUIRE NOW</b></h4>
                <div class="card custom-form rounded border-0 shadow p-4">
                    <form method="post" name="myForm" (ngSubmit)="getintouch()" #myForm="ngForm">
                        <p id="error-msg" class="mb-0"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="name" id="name" type="text" class="form-control ps-5"
                                            placeholder="Name :" [(ngModel)]="getDetails.fullname" required>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="email" id="email" type="email" class="form-control ps-5"
                                            placeholder="Email :" [(ngModel)]="getDetails.emailaddress" required email>
                                        <div *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)"
                                            class="text-danger">Invalid email</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Phone No.</label>
                                    <div class="form-icon position-relative">
                                        <input name="phone" id="phone" class="form-control ps-5" placeholder="Phone No."
                                            [(ngModel)]="getDetails.contact" pattern="[0-9]{10}">
                                        <div *ngIf="myForm.controls['phone'].invalid && (myForm.controls['phone'].dirty || myForm.controls['phone'].touched)"
                                            class="text-danger">Invalid phone number (10 digits)</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Subject</label>
                                    <div class="form-icon position-relative">
                                        <input name="subject" id="subject" class="form-control ps-5"
                                            placeholder="subject :" [(ngModel)]="getDetails.subject">
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                            placeholder="Message :" [(ngModel)]="getDetails.message"
                                            required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-grid">
                                    <button type="submit" id="submit" name="send" class="btn btn-primary"
                                        [disabled]="myForm.invalid">Get
                                        in Touch</button>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </form>

                </div>
                <!--end custom-form-->
            </div>


            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->

<a href="https://wa.link/0l9zqv;" class="btn btn-icon  back-to-top" style="background-color: green;">

    <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
    <span class="h1 ">
        <i class="  mdi mdi-whatsapp large"> </i>
    </span>



</a>

<!-- Back to top -->