import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'landrick-angular';

  constructor(private titleService: Title, private metaService: Meta, private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.titleService.setTitle(data['title']);
      this.metaService.updateTag({ name: 'description', content: data['description'] });
    });
  }
}
