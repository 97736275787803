import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.css']
})
export class TrackOrderComponent {
  Menuoption = 'center';
  Settingicon = true
  orderid
  orderdetails
  
  // public counts = ["Order Recieved", "Payment Status", "Checkplot Approval", "Order Initiated", "Expected Delivery Schedule", "Logistics"];
  // public orderStatus = "Logistics"
  // StatusList = [
  //   {
  //     StatusTitle: "Order/Files Recieved",
  //     //StatusType: "status-intransit",
  //     StatusType: "status-current",
  //     ItemStatus: "tracking-item",
  //     // OrderDate: "09 Aug 2021, 10:00am"
  //   },
  //   {
  //     StatusTitle: "CheckPlot Approval",
  //     StatusType: "status-intransit blinker",
  //     ItemStatus: "tracking-item-pending ",
  //     // OrderDate: "09 Aug 2021, 10:00am"
  //   },
  //   {
  //     StatusTitle: "Payment Status",
  //     StatusType: "status-intransit ",
  //     ItemStatus: "tracking-item-pending",
  //     // OrderDate: "09 Aug 2021, 10:00am"
  //   },
  //   {
  //     StatusTitle: "Process Initiated",
  //     StatusType: "status-intransit ",
  //     ItemStatus: "tracking-item-pending",
  //     // OrderDate: "09 Aug 2021, 10:00am"
  //   },
  //   {
  //     StatusTitle: "Drilling",
  //     StatusType: "status-intransit ",
  //     ItemStatus: "tracking-item-pending ",
  //     // OrderDate: "09 Aug 2021, 10:00am"
  //   },
  //   {
  //     StatusTitle: "Copper Plating",
  //     StatusType: "status-intransit ",
  //     ItemStatus: "tracking-item-pending ",
  //     // OrderDate: "09 Aug 2021, 10:00am"
  //   },
  //   {
  //     StatusTitle: "SMOBC",
  //     StatusType: "status-intransit ",
  //     ItemStatus: "tracking-item-pending ",
  //     // OrderDate: "09 Aug 2021, 10:00am"
  //   },
  //   {
  //     StatusTitle: "HAL",
  //     StatusType: "status-intransit ",
  //     ItemStatus: "tracking-item-pending ",
  //     // OrderDate: "09 Aug 2021, 10:00am"
  //   },
  //   {
  //     StatusTitle: "Testing",
  //     StatusType: "status-intransit ",
  //     ItemStatus: "tracking-item-pending ",
  //     // OrderDate: "09 Aug 2021, 10:00am"
  //   },
  //   {
  //     StatusTitle: "Dispatch details",
  //     StatusType: "status-intransit ",
  //     ItemStatus: "tracking-item-pending ",
  //     // OrderDate: "09 Aug 2021, 10:00am"
  //   },


  // ]
  constructor(private _data: DataService, public toastr: ToastrService) {
    console.log(this.PCB_file)
  }

  getorderstatus() {
    console.log(this.orderdetails)
    let payload = {

      "order_id": this.orderid
    }
    console.log(this.orderid)
    this._data.login("track_order_details", payload).then(res => {
      console.log(res)
      this.orderdetails = res
    })
  }
  PCB_file
  uploadedfile
  uploadedfilesize
  onFileChange(event) {
    console.log(event);

    let files: FileList = event.target.files;
    this.PCB_file = event.target.files[0].name;
    console.log(this.PCB_file)
    let PCB_file: File = files[0];
    this.uploadedfile = PCB_file
    this.uploadedfilesize = PCB_file.size
    console.log(this.uploadedfile)
    //  alert("system alert")
  }
  uploadbillfiles() {
    console.log("button")
    let payload = {
      "order_id": this.orderid,
      "file_name": this.PCB_file
    }
    if (this.uploadedfilesize > 1100000) {
      alert("Max File Size is 10MB")
    } else {
      this._data.postmethod("upload_payment_reciepts", payload).subscribe(res => {
        console.log(res)
        if (res.body.uploadUrl != '') {
          this._data.putmethod(res.body.uploadUrl, this.uploadedfile).subscribe(fileres => {
            console.log(fileres.headers)
            console.log(fileres.status)
            if (fileres.status === 200) {
              this.toastr.success('File Uploaded Successfully, Our team will acknowledge the payment and get back to you with updates');
              this.PCB_file = undefined
            } else {
              this.toastr.error('Something Went Wrong, Please Try Again')
            }
          })
        }
      })
    }
  }
}
