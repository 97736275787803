import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-requestquote',
  templateUrl: './requestquote.component.html',
  styleUrls: ['./requestquote.component.css']
})
export class RequestquoteComponent {


  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true
  constructor(private _data: DataService, public toastr: ToastrService) {
    // this.formdata.Base_Material = "FR-4"
    console.log(this.emailaddress,
      this.mobileno,
      this.Name_cust)
  }
  //    formdata={
  // Name_cust:''
  //    }
  Base_Material = "FR-4"
  PCB_Qty
  Layers = "2"
  PCB_Color = "Green"
  Surface_finish = "HASL(with lead)"
  Outer_Copper_Weight = "1 oz"
  Remove_Order_Number = "No"
  PCB_Thickness = "1.6"
  delivery_schedule = "7Days"
  Remarks
  PCB_file
  emailaddress
  mobileno
  Name_cust

  ngOnInit(): void {
  }
  upresults: any

  savepcbrequestdata() {
    console.log(this.uploadedfilesize)
    if (this.PCB_Qty === undefined) {
      alert("Enter PCB Quantity")
    } else if (this.Name_cust === undefined) {
      alert("Enter Valid Name")
    } else if (this.mobileno === undefined) {
      alert("Enter Mobile Number")
    } else if (this.emailaddress === undefined) {
      alert("Enter Valid Email")
    }
    // else if (this.PCB_file === undefined) {
    //   alert("Please upload File")
    // }
    else if (this.uploadedfilesize > 51000000) {
      alert("Max File Size is 50MB")
    } else {
      let payload = {
        base_material: this.Base_Material,
        layers: this.Layers,
        quantity: parseInt(this.PCB_Qty),
        pcb_thickness: parseFloat(this.PCB_Thickness),
        pcb_color: this.PCB_Color,
        surface_finish: this.Surface_finish,
        outer_copper_weight: this.Outer_Copper_Weight,
        delivery_schedule:this.delivery_schedule,
        name: this.Name_cust,
        email: this.emailaddress,
        contact_number: (this.mobileno).toString(),
        remarks: this.Remarks,
        file_name: this.PCB_file
      }
      console.log(payload)
      this._data.postmethod("submit_request_quote", payload).subscribe((res) => {
        // console.log(JSON.parse(res))
        //  this.upresults = res
        console.log(res.body)
        //  let filepayload = {
        //   order_id: res.body.order_id,
        //   file_name:this.PCB_file
        //  }
        if (res.body.uploadUrl != '') {
          this._data.putmethod(res.body.uploadUrl, this.uploadedfile).subscribe(fileres => {
            console.log(fileres.headers)
            console.log(fileres.status)
            if (fileres.status === 200) {
              this.toastr.success('Quote Successfully sent');
              this.PCB_file = ''
              this.emailaddress = ''
              this.mobileno = ''
              this.Name_cust = ''
              this.PCB_Qty = ''
              this.PCB_file = undefined
            }
          })
        }else{
          this.toastr.success('Quote Successfully sent');
              this.PCB_file = ''
              this.emailaddress = ''
              this.mobileno = ''
              this.Name_cust = ''
              this.PCB_Qty = ''
        }

        // this._data.putservice(url, this.PCB_file).then(ress =>{
        //   console.log(ress)
        // })
      })
    }
  }
  uploadedfile
  uploadedfilesize
  onFileChange(event) {
    console.log(event);

    let files: FileList = event.target.files;
    this.PCB_file = event.target.files[0].name;
    console.log(this.PCB_file)
    let PCB_file: File = files[0];
    this.uploadedfile = PCB_file
    this.uploadedfilesize = PCB_file.size
    // console.log(filesize)
    //  alert("system alert")
  }
  savepcbdetails() {
    console.log(this.PCB_Color)
    console.log(this.PCB_file)
    this.savepcbrequestdata()
  }
}

