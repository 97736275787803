<!-- <section class="bg-half-90 d-table w-100 it-home" style="background: url('assets/images/product20.png') center center;"
    id="home">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6 col-12">
                <h3 class="text-white">Sharacircuits</h3>
                <h3 class="text-white"><br> </h3>
                <h1 class="fw-bold text-white title-dark mt-2 mb-3">  <br> Excellent Quality PCB</h1>
                <p class="para-desc text-white">Super lightweight and excellent quality.</p>
            </div>

            <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            </div>
        </div>
    </div>
</section> -->


<section class="section" style="padding-bottom:10px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/course/prod13.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h4 class="title mb-4">PCB Fabrication</h4>
                    <p class="text-muted para-desc mx-auto">Shara Circuits specializes in comprehensive PCB solutions,
                        encompassing Design, Schematics, and Component Procurement. We are dedicated to delivering
                        high-quality products at cost-effective prices, ensuring prompt delivery for our esteemed
                        clients.
                    </p>
                    <div class="mt-4" [ngxScrollTo]="'#getInquiry'">
                        <a href="javascript:void(0)" class="btn btn-primary">Inquiry</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <!--END container-->
</section>

<!-- Start Terms & Conditions -->
<section class="section" style="padding:10px">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">Manufacturing Capacity</h5>
                        <p class="text-muted">
                            At Shara Circuits, we pride ourselves on producing PCBs using traditional materials like FR4
                            PCB and advanced FR4-High TG-170 PCBs. Our offerings range from 4-layer to 32-layer
                            Multilayer PCBs, Impedance Control PCBs, Anylayer HDI PCBs, and Micro-via Blind/Buried hole
                            PCBs. We also specialize in PCBs with transparent ink solder masks, carbon ink, and the
                            unique blue peelable mask.
                        </p>
                        <p class="text-muted">
                            For any inquiries or detailed information, connect with us directly at
                            info@sharacircuits.com.
                        </p>
                        <p class="text-muted">
                            Our commitment to excellence has led us to invest in cutting-edge machinery and develop a
                            vertically integrated infrastructure. This synergy of state-of-the-art technology and expert
                            personnel drives our ambition to be a top-tier, all-inclusive PCB & PCBA service provider
                            for both local and international clientele.
                        </p>
                        <p class="text-muted">
                            Understanding our offerings:
                        </p>
                        <p class="text-muted">
                            <b>Single-sided PCBs</b>: Typically made from single-sided copper-clad laminate, these
                            boards have
                            circuits on one side and plug-in components on the other.
                        </p>
                        <p class="text-muted">
                            <b>Double-sided PCBs</b>: These are crafted from double-sided copper-clad laminates and have
                            two circuit patterns – the Component Side and the Solder Side. The walls of the vias in
                            these boards are copper-plated, facilitating conduction between the top and bottom layers.
                        </p>
                        <p class="text-muted">
                            <b>Multi-layer PCBs</b>: Building on the foundation of double-sided boards, multi-layer PCBs
                            incorporate an internal power layer and ground layer. They consist of at least three or more
                            circuit layers. Vias ensure conductivity across each substrate layer, and these layers are
                            seamlessly fused through a lamination process, with insulating material positioned between
                            each layer. The design and routing of these boards aim to optimize internal electrical layer
                            pathways, promoting more efficient circuit board routing and paving the way for products
                            with large capacities in compact sizes.
                        </p>
                        <p class="text-muted">
                            With the evolution of electronic technology and the proliferation of integrated circuits,
                            our multilayer PCBs are swiftly advancing towards high-precision and high-density digital
                            integration. This progression is evident in the increasing fine line widths, reduced line
                            spacing, micro aperture penetrations, and the adoption of HDI technology to cater to the
                            ever-evolving market needs.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->



<section class="section" style="padding:10px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="row">
                    <!-- Table Start -->
                    <div class="col-12">
                        <div class="component-wrapper rounded shadow">
                            <div class="p-4">
                                <h5 class="card-title">PCB Fabrication Capability</h5>
                                <div class="table-responsive bg-white shadow rounded">
                                    <table class="table mb-0 table-center text-muted">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="border-bottom">Engineer</th>
                                                <th scope="col" class="border-bottom">Item</th>
                                                <th scope="col" class="border-bottom">Manufacturing Capability</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Material</td>
                                                <td>Type</td>
                                                <td>FR-4, HIGH TG FR-4-TG170/TG180, CEM-
                                                    3,Halogen- Free Rogers, Arlon, Taconic, Isola, PTFE,
                                                    Bergquist, Polyimide, Aluminum base, Copper
                                                    base, Heavy copper foil</td>
                                            </tr>
                                            <tr>
                                                <td>Material</td>
                                                <td>Thickness</td>
                                                <td>0.2mm ~ 10mm</td>
                                            </tr>
                                            <tr>
                                                <td>Production Type</td>
                                                <td>Surface Treatment</td>
                                                <td>HASL, HASL lead-free HAL Flash gold,immersion
                                                    gold, 0SP,Gold Finger Palting, Selective thick gold
                                                    plating, immersion silver,immersion tin, Carbon
                                                    ink peelable mask
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Production Type</td>
                                                <td>No. of Layer</td>
                                                <td>1L-56L
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cut Lamination</td>
                                                <td>Working Panel Size</td>
                                                <td>Max:650mmx1200mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Inner Layer</td>
                                                <td>Inner Core thickness</td>
                                                <td>0.1~2.0mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Inner Layer</td>
                                                <td>Conductor Width/spacing</td>
                                                <td>Min:3/3mil
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Inner Layer</td>
                                                <td>Alignment</td>
                                                <td>2.0mil
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Dimension</td>
                                                <td>Board Thickness Tolerance</td>
                                                <td>+10%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Dimension</td>
                                                <td>Inter layer Alignment</td>
                                                <td>+3mil
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Drilling</td>
                                                <td>Drilling Diameter</td>
                                                <td>Min:0.15MM (Laser Drill:0.1MM)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Drilling</td>
                                                <td>PTH Tolerance</td>
                                                <td>+0.075mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Drilling</td>
                                                <td>NPTH Tolerance</td>
                                                <td>+0.05mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Drilling</td>
                                                <td>Hole Position Tolerance</td>
                                                <td>+0.076mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>PTH + Panel Plating</td>
                                                <td>Hole Wall Copper Thickness</td>
                                                <td>>=20um
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>PTH + Panel Plating</td>
                                                <td>Uniformity</td>
                                                <td>>=90%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>PTH + Panel Plating</td>
                                                <td>Aspect Ratio</td>
                                                <td>12:01
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Outer Layer</td>
                                                <td>Conductor Width</td>
                                                <td>Min:3mil
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Outer Layer</td>
                                                <td>Conductor Width</td>
                                                <td>Min:3mil
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Pattern Plating</td>
                                                <td>Finished Copper Thickness</td>
                                                <td>1oz ~100z
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Etching</td>
                                                <td>Under Cut</td>
                                                <td>>=2.0
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>EING/FLASH GOLD</td>
                                                <td>Nickel Thickness</td>
                                                <td>>=100u”
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>EING/FLASH GOLD</td>
                                                <td>Gold Thickness</td>
                                                <td>1~3u"
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Solder Mask</td>
                                                <td>Thickness</td>
                                                <td>10~25um
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Solder Mask</td>
                                                <td>Solder Mask Bridge</td>
                                                <td>4mil
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Solder Mask</td>
                                                <td>Plug Hole Dia</td>
                                                <td>0.3~0.6mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Solder Mask Color</td>
                                                <td></td>
                                                <td>Green, Matte Green, White, Matte White, Black Matte Black,
                                                    Yellow,Red,Blue,Transparent ink
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Silkscreen Color</td>
                                                <td></td>
                                                <td>5/5mil
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Legend</td>
                                                <td>Line Width/Line Spacing</td>
                                                <td>White, Black, Yellow, Red, Blue
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Gold Finger</td>
                                                <td>Nickel Thickness</td>
                                                <td>>=120u”
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Gold Finger</td>
                                                <td>Gold Thickness</td>
                                                <td>1 ~ 80u”
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Hot Air Level</td>
                                                <td>Tin Thickness</td>
                                                <td>100-300u”
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>OSP</td>
                                                <td>Membrane Thickness</td>
                                                <td>0.2~0.4um
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Routing</td>
                                                <td>Tolerance of Dimension</td>
                                                <td>+0 1mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Routing</td>
                                                <td>Slot Size</td>
                                                <td>Min:0.6mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Routing</td>
                                                <td>Cutter Diameter</td>
                                                <td>0.8mm-2.4mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Punching</td>
                                                <td>Outline Tolerance</td>
                                                <td>+-0.1mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Punching</td>
                                                <td>Slot Size</td>
                                                <td>Min:0.7mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>V-CUT</td>
                                                <td>V-CUT Dimension</td>
                                                <td>Min:60mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>V-CUT</td>
                                                <td>Angle</td>
                                                <td>15°30°45°
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>V-CUT</td>
                                                <td>Remain Thickness Tolerance</td>
                                                <td>+-0.1mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Beveling</td>
                                                <td>Beveling Dimension</td>
                                                <td>30-300mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Test</td>
                                                <td>Testing Voltage</td>
                                                <td>250V
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Test</td>
                                                <td>Max.Dimension</td>
                                                <td>540x400mm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Impedance Control</td>
                                                <td>Tolerance</td>
                                                <td>+-10%
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Peel Strength</td>
                                                <td></td>
                                                <td>1.4N/mm
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end col-->
                    <!-- Table End -->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
    </div>
</section>


<!-- Start Terms & Conditions -->
<section class="section" style="padding:10px">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <!-- <h5 class="card-title">Manufacturing Capacity</h5> -->
                        <p class="text-muted">
                            <b>Q1. Who is Shara Circuits?</b><br>Shara Circuits stands as a leading manufacturer in the
                            PCB industry, offering a diverse range of products including single-sided, double-sided, and
                            multilayer PCBs, as well as Flex PCB production.

                            Our product line encompasses multilayer PCBs, high-frequency PCBs, aluminum core PCBs,
                            copper-based PCBs, thick copper PCBs, rigid-flexible PCBs, HDI PCBs, anylayer PCBs,
                            Impedance Control PCBs, Carbon ink PCBs, Peelable Blue mask PCBs, BT Resin PCBs, and
                            Blind/buried via PCBs.
                        </p>
                        <p class="text-muted">
                            <b>Q2. How do you maintain high quality?</b><br>Quality is our utmost priority. All our
                            products are manufactured in compliance with global certification standards such as ISO9001,
                            ISO14001, and UL, among others. We encourage potential partners to place sample orders to
                            experience and assess the superior quality we deliver.
                        </p>
                        <p class="text-muted">
                            <b>Q3. Which raw materials are employed in your production?</b><br>We utilize top-grade raw
                            materials sourced from trusted suppliers including Kingboard, ShenYi, ITEQ, Nnanya, Rogers,
                            Arlon, Isola, Taconic, Dupont, Polyimide(P1), Aluminium base, and Cu base to name a few.
                        </p>
                        <p class="text-muted">
                            <b>Q4. Do you offer quick-turnaround times for PCB sample production?</b><br>Absolutely! We
                            prioritize speed without compromising on quality. For 2-layer PCB samples, we can ensure a
                            24-hour quick delivery. For 4-layer PCB samples, our turnaround is 48 hours. For 6-layer and
                            8-layer PCB samples, we offer a 72-hour swift delivery, among other efficient timeframes for
                            various specifications.
                        </p>
                        <div class="row">
                            <!-- Table Start -->
                            <div class="col-12">
                                <div class="table-responsive bg-white shadow rounded">
                                    <table class="table mb-0 table-center text-muted">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="border-bottom">Layer</th>
                                                <th scope="col" class="border-bottom">Quick Turn (hours)</th>
                                                <th scope="col" class="border-bottom">Usual Time (days)</th>
                                                <th scope="col" class="border-bottom">Mass Production (days)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>2</td>
                                                <td>24</td>
                                                <td>4</td>
                                                <td>10</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>48</td>
                                                <td>6</td>
                                                <td>12</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>78</td>
                                                <td>8</td>
                                                <td>14</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>96</td>
                                                <td>10</td>
                                                <td>16</td>
                                            </tr>
                                            <tr>
                                                <td>10+</td>
                                                <td>120+</td>
                                                <td>12</td>
                                                <td>18</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--end col-->
                            <!-- Table End -->
                        </div>
                        <!--end row-->
                        <p class="text-muted">
                            <b>Q6. What is how many MOQ?</b><br>There is no MOQ for printed circuit board and pcba
                            assemble, same We are able to handle Small as well as large volume production with
                            flexibility.
                        </p>
                        <p class="text-muted">
                            <b>Q7. What is Shipping cost?</b><br>The shipping cost is determined by shipping method you
                            choose, the destination, weight, packing size of the goods. if need quote to you for the
                            shipping cost, Please let us know.
                        </p>
                        <p class="text-muted">
                            <b>Q8. How to test PCB Boards and PCBA Products?</b><br>With Fixture Testing, Fly probe
                            testing, AOI, Impedance test, FQC, other test method ect... for pcb boards , AOI and X-RAY,
                            FQC, other test method ect... for pba assemble
                        </p>
                        <p class="text-muted">
                            <b>Q9. If can you provide free sample?</b><br>Yes, but you need make the payment to us for
                            pcb samples at first, is ok? we will return the pcb sample cost to you when your next big
                            batch orders. welcome to experience our service and quality.
                        </p>
                        <p class="text-muted">
                            <b>Q10. What is standard shipping time?</b><br>Normal it's shipping time about need 3-5days
                            for DHL, UPS, FEDEX, TNT, EMS.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->


<!-- Start Contact -->
<section class="section " id="getInquiry" style="padding-top:10px">

    <!--end container-->
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                <h4 style="text-align: center;" class="card-title"><b> ENQUIRE NOW</b></h4>
                <div class="card custom-form rounded border-0 shadow p-4">
                    <form method="post" name="myForm" (ngSubmit)="getintouch()" #myForm="ngForm">
                        <p id="error-msg" class="mb-0"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="name" id="name" type="text" class="form-control ps-5"
                                            placeholder="Name :" [(ngModel)]="getDetails.fullname" required>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="email" id="email" type="email" class="form-control ps-5"
                                            placeholder="Email :" [(ngModel)]="getDetails.emailaddress" required email>
                                        <div *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)"
                                            class="text-danger">Invalid email</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Phone No.</label>
                                    <div class="form-icon position-relative">
                                        <input name="phone" id="phone" class="form-control ps-5" placeholder="Phone No."
                                            [(ngModel)]="getDetails.contact" pattern="[0-9]{10}">
                                        <div *ngIf="myForm.controls['phone'].invalid && (myForm.controls['phone'].dirty || myForm.controls['phone'].touched)"
                                            class="text-danger">Invalid phone number (10 digits)</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Subject</label>
                                    <div class="form-icon position-relative">
                                        <input name="subject" id="subject" class="form-control ps-5"
                                            placeholder="subject :" [(ngModel)]="getDetails.subject">
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                            placeholder="Message :" [(ngModel)]="getDetails.message"
                                            required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-grid">
                                    <button type="submit" id="submit" name="send" class="btn btn-primary"
                                        [disabled]="myForm.invalid">Get
                                        in Touch</button>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </form>
                </div>
                <!--end custom-form-->
            </div>


            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->

<a href="https://wa.link/0l9zqv;" class="btn btn-icon  back-to-top" style="background-color: green;">

    <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
    <span class="h1 ">
        <i class="  mdi mdi-whatsapp large"> </i>
    </span>



</a>

<!-- Back to top -->