<!-- Start Contact -->
<section class="section pt-5 mt-4" id="home">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
        <div class="card map border-0">
          <div class="card-body p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.1328471032816!2d77.52007367589577!3d13.090766012252873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae23fcdefdce4d%3A0x2008b79d9e78000c!2sShara%20Circuits%20Pvt.%20Ltd.%20PCB%20Manufacturers!5e0!3m2!1sen!2sin!4v1691297979594!5m2!1sen!2sin"
              style="border:0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card custom-form rounded border-0 shadow p-4">
          <form method="post" name="myForm" (ngSubmit)="getintouch()" #myForm="ngForm">
            <p id="error-msg" class="mb-0"></p>
            <div id="simple-msg"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Name <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :"
                      [(ngModel)]="getDetails.fullname" required>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Email <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :"
                      [(ngModel)]="getDetails.emailaddress" required email>
                    <div
                      *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)"
                      class="text-danger">Invalid email</div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Phone No.</label>
                  <div class="form-icon position-relative">
                    <input name="phone" id="phone" class="form-control ps-5" placeholder="Phone No."
                      [(ngModel)]="getDetails.contact" pattern="[0-9]{10}">
                    <div
                      *ngIf="myForm.controls['phone'].invalid && (myForm.controls['phone'].dirty || myForm.controls['phone'].touched)"
                      class="text-danger">Invalid phone number (10 digits)</div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Subject</label>
                  <div class="form-icon position-relative">
                    <input name="subject" id="subject" class="form-control ps-5" placeholder="subject :"
                      [(ngModel)]="getDetails.subject">
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Comments <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <textarea name="comments" id="comments" rows="4" class="form-control ps-5" placeholder="Message :"
                      [(ngModel)]="getDetails.message" required></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <button type="submit" id="submit" name="send" class="btn btn-primary" [disabled]="myForm.invalid">Get
                    in Touch</button>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>

        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="title-heading ms-lg-4">
          <h4 class="mb-4">Contact Details</h4>
          <p class="text-muted">Start working with Sharacircuits
            everything you need related to PCB.</p>
          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Email</h6>
              <a href="mailto:contact@example.com" class="text-primary">info@sharacircuits.com</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Phone</h6>
              <a href="tel:+91 9502408846" class="text-primary">+91 9502408846</a><br>
              <a href="tel:+91 9035258846" class="text-primary">+91 9035258846</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Location</h6>

              <a (click)="mapView(content)" data-type="iframe" class="video-play-icon text-primary">View on Google
                map</a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.1328471032816!2d77.52007367589577!3d13.090766012252873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae23fcdefdce4d%3A0x2008b79d9e78000c!2sShara%20Circuits%20Pvt.%20Ltd.%20PCB%20Manufacturers!5e0!3m2!1sen!2sin!4v1691297979594!5m2!1sen!2sin"
                    height="450" width="750">
                  </iframe>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->

<a href="https://wa.link/0l9zqv;" class="btn btn-icon  back-to-top" style="background-color: green;">

  <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
  <span class="h1 ">
    <i class="  mdi mdi-whatsapp large"> </i>
  </span>



</a>

<!-- Back to top -->