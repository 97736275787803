<section class="section" style="padding-bottom:10px">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/products/Clipboard1.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h4 class="title mb-4">PCB Design Services</h4>
                    <!-- <p class="text-muted para-desc mx-auto">
                        In the digital age, the heart of every electronic innovation lies within its circuitry. At the
                        core of this circuitry is the intricate design of the PCB (Printed Circuit Board). Our PCB
                        design services are the bridge between your idea and its realization, ensuring your device
                        performs at its peak.
                    </p> -->
                    <p class="text-muted para-desc mx-auto">
                        Welcome to Shara Circuits Pvt Ltd, your premier destination of cutting edge PCB design services.
                        Our team of experienced engineers and designers harness the latest industry leading software and
                        technology to craft PCB.
                        Explore our comprehensive range of PCB design service and discover how we can help you achieve
                        unparalleled results in your electronic venture.
                    </p>
                    <div class="mt-4" [ngxScrollTo]="'#getInquiry'">
                        <a href="javascript:void(0)" class="btn btn-primary">Inquiry</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <!--END container-->
</section>

<!-- Start Terms & Conditions -->
<section class="section" style="padding:10px">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">What We Offer</h5>
                        <p class="text-muted">
                            <b>Expertise in Advanced PCB Design:</b><br>From simple single-layer boards to complex
                            multi-layered designs, we possess the expertise to bring your vision to life. Our team is
                            skilled in managing the challenges of high-speed, high-frequency, and high-density circuits.
                        </p>
                        <p class="text-muted">
                            <b>Holistic Approach:</b><br>Our design approach integrates the mechanical, electrical, and
                            software aspects of your project, ensuring a seamless transition from design to
                            manufacturing.
                        </p>
                        <p class="text-muted">
                            <b>Comprehensive Testing:</b><br>Before your PCB design proceeds to the manufacturing stage,
                            we run a series of stringent tests. This includes DFM (Design for Manufacturability) checks
                            and simulations to ensure optimal performance.
                        </p>
                        <p class="text-muted">
                            <b>Up-to-date with Latest Technologies:</b><br>As the tech landscape evolves, so do we. We
                            continuously update our knowledge and tools to provide you with designs compatible with the
                            latest components and manufacturing techniques.
                        </p>
                        <p class="text-muted">
                            <b>Collaborative Process: </b><br>We believe in collaboration. Our team works closely with
                            you, providing regular updates and incorporating feedback at every stage of the design
                            process.
                        </p>
                        <p class="text-muted">
                            <b>Applications:</b><br>Our PCB design services cater to a diverse range of sectors. Whether
                            you're in the field of consumer electronics, telecommunications, automotive, medical
                            devices, industrial automation, or aerospace, we tailor our designs to meet the unique
                            requirements of your industry.
                        </p>
                        <p class="text-muted">
                            <b>Embark on Your Next Innovation with Us:</b><br>The foundation of every successful
                            electronic product is a meticulously crafted PCB design. Entrust us with this critical
                            phase, and witness your idea evolve into a tangible, efficient, and reliable product. For
                            inquiries and further details, reach out to our dedicated team, and let's shape the future
                            together.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->


<!-- Start Contact -->
<section class="section " id="getInquiry" style="padding-top:10px">

    <!--end container-->
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                <h4 style="text-align: center;" class="card-title"><b> ENQUIRE NOW</b></h4>
                <div class="card custom-form rounded border-0 shadow p-4">

                    <form method="post" name="myForm" (ngSubmit)="getintouch()" #myForm="ngForm">
                        <p id="error-msg" class="mb-0"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="name" id="name" type="text" class="form-control ps-5"
                                            placeholder="Name :" [(ngModel)]="getDetails.fullname" required>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <input name="email" id="email" type="email" class="form-control ps-5"
                                            placeholder="Email :" [(ngModel)]="getDetails.emailaddress" required email>
                                        <div *ngIf="myForm.controls['email'].invalid && (myForm.controls['email'].dirty || myForm.controls['email'].touched)"
                                            class="text-danger">Invalid email</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Phone No.</label>
                                    <div class="form-icon position-relative">
                                        <input name="phone" id="phone" class="form-control ps-5" placeholder="Phone No."
                                            [(ngModel)]="getDetails.contact" pattern="[0-9]{10}">
                                        <div *ngIf="myForm.controls['phone'].invalid && (myForm.controls['phone'].dirty || myForm.controls['phone'].touched)"
                                            class="text-danger">Invalid phone number (10 digits)</div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Subject</label>
                                    <div class="form-icon position-relative">
                                        <input name="subject" id="subject" class="form-control ps-5"
                                            placeholder="subject :" [(ngModel)]="getDetails.subject">
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                                    <div class="form-icon position-relative">
                                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                                            placeholder="Message :" [(ngModel)]="getDetails.message"
                                            required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-grid">
                                    <button type="submit" id="submit" name="send" class="btn btn-primary"
                                        [disabled]="myForm.invalid">Get
                                        in Touch</button>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </form>
                </div>
                <!--end custom-form-->
            </div>


            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->

<a href="https://wa.link/0l9zqv;" class="btn btn-icon  back-to-top" style="background-color: green;">

    <!-- <i-feather name="message-circle" class=" feather-32"> what up baby</i-feather> -->
    <span class="h1 ">
        <i class="  mdi mdi-whatsapp large"> </i>
    </span>



</a>

<!-- Back to top -->